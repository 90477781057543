import client, { handleError } from "../utils/client";
import env from "../env";
import { DeviceDelete } from "../common/types/deviceDelete";

export const getUserDevices = (userId: number) => {
    return client.get(`${env.apiUrl}/api/devices/${userId}`).catch(handleError);
}

export const removeUserDevice = (deviceDelete: DeviceDelete) => {
    return client.post(`${env.apiUrl}/api/devices`, deviceDelete).catch(handleError);
}

export const removeAllUserDevices = (userId) => {
    return client.delete(`${env.apiUrl}/api/devices/${userId}`).catch(handleError);
}

export const removeGroupDevicesRequest = (groupId) => {
    return client.delete(`${env.apiUrl}/api/devices/group/${groupId}`).catch(handleError);
}