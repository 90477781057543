import React, {useEffect, useState} from 'react';
import { ChartType } from '../../enums/chart-type';
import { SideBar } from '../../../../common/sidebar/sidebar';

import './styles.scss';

export interface SideStatsControlsProps {
    setChartData: (data: any) => void;
    plotData: any;
    checkCaptionForRequest: (caption: string) => void;
}

const SideStatsControls: React.FC<SideStatsControlsProps> = ({ setChartData, plotData, checkCaptionForRequest }) => {

    useEffect(() => {
        onClickUsersByClient();
    }, [])

    const checkBeforeDataSetting = (dataObj) => {
        if (plotData.caption === dataObj.caption && plotData.type === dataObj.type) {
            return;
        } else if (plotData.type !== dataObj.type
            && dataObj.type !== ChartType.GeoChart
            && dataObj.type !== ChartType.PieChart
            && plotData.type !== ChartType.GeoChart
            && plotData.type !== ChartType.PieChart) {
            setChartData(dataObj);
            checkCaptionForRequest(dataObj.caption);
        } else {
            setChartData(dataObj);
        }
    }

    const onClickUsersByClient = () => {
        const chartData = {
            type: ChartType.BarChart,
            caption: 'Users',
            traces: 'Users',
            columnSubtitle: 'Client',
            scaleSubtitle: 'Users count',
            dataField: 'usersCount',
            dataSet: [],
            isLineChartsRequired: true,
            isStacked: false
        }
        checkBeforeDataSetting(chartData);
    }

    const onClickSessionByClient = () => {
        const chartData = {
            type: ChartType.BarChart,
            caption: 'Sessions',
            traces: 'Session',
            columnSubtitle: 'Client',
            scaleSubtitle: 'Sessions count',
            dataField: 'sessionsCount',
            dataSet: [],
            isLineChartsRequired: true,
            isStacked: false
        }
        checkBeforeDataSetting(chartData);
    }

    const onClickSessionByDeviceByClient = () => {
        const chartData = {
            type: ChartType.BarChart,
            caption: 'Devices',
            traces: ['Desktop session', 'Tablet session', 'Mobile session'],
            columnSubtitle: 'Client',
            scaleSubtitle: 'Sessions count',
            dataField: ['desktopSessions', 'tabletSessions', 'mobileSessions'],
            dataSet: [],
            isLineChartsRequired: true,
            isStacked: false
        }
        checkBeforeDataSetting(chartData);
    }

    const onClickTotalDurationByClient = () => {
        const chartData = {
            type: ChartType.BarChart,
            caption: 'Total Duration',
            traces: 'Duration hours',
            columnSubtitle: 'Client',
            scaleSubtitle: 'Duration hours',
            dataField: 'durationHours',
            dataSet: [],
            isLineChartsRequired: false,
            isStacked: false
        }
        checkBeforeDataSetting(chartData);
    }

    const onClickSessionByVideoTypeByClient = () => {
        const chartData = {
            type: ChartType.BarChart,
            caption: 'Stream Format',
            traces: ['HD Live Session', 'SD Live Session', 'HD Review Session', 'SD Review Session', 'HD VOD Session', 'SD VOD Session'],
            columnSubtitle: 'Client',
            scaleSubtitle: 'Sessions count by stream format',
            dataField: ['hdLiveSessions', 'sdLiveSessions', 'hdReviewSessions', 'sdReviewSessions', 'hdVodSessions', 'sdVodSessions'],
            dataSet: [],
            isLineChartsRequired: true,
            isStacked: false
        }
        checkBeforeDataSetting(chartData);
    }

    const onClickDurationByVideoTypeByClient = () => {
        const chartData = {
            type: ChartType.BarChart,
            caption: 'Duration By Stream Format',
            traces: ['HD Live Duration Hours', 'SD Live Duration Hours', 'HD Review Duration Hours', 'SD Review Duration Hours', 'HD VOD Duration Hours', 'SD VOD Duration Hours'],
            columnSubtitle: 'Client',
            scaleSubtitle: 'Sessions duration hours by stream format',
            dataField: ['hdLiveDurationHours', 'sdLiveDurationHours', 'hdReviewDurationHours', 'sdReviewDurationHours', 'hdVodDurationHours', 'sdVodDurationHours'],
            dataSet: [],
            isLineChartsRequired: true,
            isStacked: false
        }
        checkBeforeDataSetting(chartData);
    }

    const onClickSportBySessionByClient = () => {
        const chartData = {
            type: ChartType.BarChart,
            caption: 'Sports',
            traces: [],
            columnSubtitle: 'Client',
            scaleSubtitle: 'Sessions count by certain sport',
            dataField: 'sports',
            nestedData: {
                name: 'sport',
                value: 'count'
            },
            dataSet: [],
            isLineChartsRequired: true,
            isStacked: true
        }
        checkBeforeDataSetting(chartData);
    }

    const onClickAverageNumberOfScreensByClient = () => {
        const chartData = {
            type: ChartType.BarChart,
            caption: 'Average Screens',
            traces: 'Player',
            columnSubtitle: 'Client',
            scaleSubtitle: 'Average screens count',
            dataField: 'screensCount',
            dataSet: [],
            isLineChartsRequired: true,
            isStacked: false
        }
        checkBeforeDataSetting(chartData);
    }

    const onClickCountryByClient = () => {
        const chartData = {
            type: ChartType.GeoChart,
            caption: 'Locations',
            traces: 'Country',
            columnSubtitle: 'Client',
            scaleSubtitle: 'Locations',
            dataField: 'countries',
            nestedData: {
                name: 'country',
                value: 'usersCount'
            },
            dataSet: [],
            isLineChartsRequired: false,
            isStacked: false
        }
        checkBeforeDataSetting(chartData);
    }

    const onClickCountryByClientPieCharts = () => {
        const chartData = {
            type: ChartType.PieChart,
            caption: 'Locations (for each client)',
            traces: 'Country',
            columnSubtitle: 'Client',
            scaleSubtitle: 'Locations',
            dataField: 'countries',
            nestedData: {
                name: 'country',
                value: 'usersCount'
            },
            dataSet: [],
            multiChart: true,
            isLineChartsRequired: false,
            isStacked: false
        }
        checkBeforeDataSetting(chartData);
    }

    const onClickNumberOfDownloadsByClient = () => {
        const chartData = {
            type: ChartType.BarChart,
            caption: 'Downloads',
            traces: 'Downloads count',
            columnSubtitle: 'Client',
            scaleSubtitle: 'Downloads count',
            dataField: 'downloadsCount',
            dataSet: [],
            isLineChartsRequired: true,
            isStacked: false
        }
        checkBeforeDataSetting(chartData);
    }

    const onClickNumberOfEventsByClient = () => {
        const chartData = {
            type: ChartType.BarChart,
            caption: 'Distinct Events',
            traces: 'Events count',
            columnSubtitle: 'Client',
            scaleSubtitle: 'Distinct events count',
            dataField: 'eventsCount',
            dataSet: [],
            isLineChartsRequired: true,
            isStacked: false
        }
        checkBeforeDataSetting(chartData);
    }

    const onClickContentProvidersBySessionByClient = () => {
        const chartData = {
            type: ChartType.BarChart,
            caption: 'Content Providers',
            traces: [],
            columnSubtitle: 'Client',
            scaleSubtitle: 'Sessions count by provider',
            dataField: 'contentProviders',
            nestedData: {
                name: 'contentProvider',
                value: 'count'
            },
            dataSet: [],
            isLineChartsRequired: true,
            isStacked: true
        }
        checkBeforeDataSetting(chartData);
    }

    return (
        <SideBar
            name={'Detail'}
            items={[{
                name: 'Users',
                onClick: onClickUsersByClient,
                iconName: 'chart bar'
            },
                {
                    name: 'Sessions',
                    onClick: onClickSessionByClient,
                    iconName: 'chart bar'
                },
                {
                    name: 'Devices',
                    onClick: onClickSessionByDeviceByClient,
                    iconName: 'chart bar'
                },
                {
                    name: 'Total Duration',
                    onClick: onClickTotalDurationByClient,
                    iconName: 'chart bar'
                },
                {
                    name: 'Stream Format',
                    onClick: onClickSessionByVideoTypeByClient,
                    iconName: 'chart bar'
                },
                {
                    name: 'Duration By Stream Format',
                    onClick: onClickDurationByVideoTypeByClient,
                    iconName: 'chart bar'
                },
                {
                    name: 'Sports',
                    onClick: onClickSportBySessionByClient,
                    iconName: 'chart bar'
                },
                {
                    name: 'Average Screens',
                    onClick: onClickAverageNumberOfScreensByClient,
                    iconName: 'chart bar'
                },
                {
                    name: 'Locations',
                    onClick: () => null,
                    iconName: 'map marker alternate',
                    childen: [{
                        name: 'Geo Chart',
                        onClick: onClickCountryByClient,
                        iconName: 'map market alternate'
                    }, {
                        name: 'Pie Chart',
                        onClick: onClickCountryByClientPieCharts,
                        iconName: 'chart pie'
                    }]
                },
                {
                    name: 'Downloads',
                    onClick: onClickNumberOfDownloadsByClient,
                    iconName: 'chart bar'
                },
                {
                    name: 'Distinct Events',
                    onClick: onClickNumberOfEventsByClient,
                    iconName: 'chart bar'
                },
                {
                    name: 'Content Providers',
                    onClick: onClickContentProvidersBySessionByClient,
                    iconName: 'chart bar'
                },
            ]}
        />
    );


}

export default SideStatsControls;
















