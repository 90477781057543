import client from "../utils/client"
import env from "../env";
import { handleError } from './../utils/client';
import {Group} from "../common/types/group";

export const getGroup = (id: number) => {
    return client.get(`${env.apiUrl}/api/groups/${id}`).catch(handleError);
}

export const getGroups = () => {
    return client.get(`${env.apiUrl}/api/groups/preview`).catch(handleError);
}

export const postGroup = (group: Group) => {
    return client.post(`${env.apiUrl}/api/groups`, group).catch(handleError);
}

export const putGroup = (group: Group) => {
    return client.put(`${env.apiUrl}/api/groups`, group).catch(handleError);
}

export const deleteGroup = (x: { groupId: string, reason: string, hardDelete: boolean }) => {
    return client.delete(`${env.apiUrl}/api/groups/${x.groupId}/${x.hardDelete ? 'hard' : 'soft'}?reason=` + x.reason).catch(handleError);
}

export const restoreGroupRequest = (groupId: number) => {
    return client.put(`${env.apiUrl}/api/groups/${groupId}/restore`).catch(handleError);
}

export const downloadGroupInfo = async () => {
    const response = await client.get(`${env.apiUrl}/api/groups/download`);
    const blob = new Blob([response.data], { type: 'text/csv' });

    if (navigator.msSaveBlob) {
        // Foe IE and Edge purposes
        return navigator.msSaveBlob(blob, 'OTT_Clients.csv');
    }

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'OTT_Clients.csv');
    document.body.appendChild(link);
    link.click();
}
