import React from "react";
import { Device } from "../../../../../../common/types/device";
import { DeviceDelete } from "../../../../../../common/types/deviceDelete";
import { User } from "../../../../../../common/types/user";
import { Button, Icon, Popup, Table, Tab } from "semantic-ui-react";
import moment from "moment";
import './styles.scss'
import {useDispatch} from "react-redux";
import {removeAllDevicesRoutine} from "../../../../../../redux/routines";

export interface IDevicesTabProps {
    removeUserDevice: (deleteModel: DeviceDelete) => any;
    user: User;
    userDevices: Device[];
}

const DevicesTab: React.FC<IDevicesTabProps> = ({ removeUserDevice, user, userDevices }) => {
    const dispatch = useDispatch();

    return (
        <Tab.Pane>
            {userDevices && userDevices.length === 0
                ? <h4>No devices found for this user</h4>
                : <div className="device-modal__container">
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={3}>Hash</Table.HeaderCell>
                                <Table.HeaderCell width={3}>Device OS</Table.HeaderCell>
                                <Table.HeaderCell width={3}>Device type</Table.HeaderCell>
                                <Table.HeaderCell width={3}>Last login</Table.HeaderCell>
                                <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                userDevices.map(d => {
                                    return <Table.Row key={d.uuid}>
                                        <Table.Cell>{d.uuid}</Table.Cell>
                                        <Table.Cell>{d.os}</Table.Cell>
                                        <Table.Cell>{d.type}</Table.Cell>
                                        <Table.Cell>
                                            {d.lastLoginDate.toString() !== "0001-01-01T00:00:00" && moment(d.lastLoginDate).format('MMMM Do YYYY, h:mm:ss a')}
                                        </Table.Cell>
                                        <Table.Cell className='action-column'>
                                            <Popup
                                                content='Delete'
                                                trigger={
                                                    <Button
                                                        onClick={() => removeUserDevice({ deviceUid: d.uuid, userId: user.id })}
                                                        secondary>
                                                        <Icon style={{ margin: 0 }} name='delete' />
                                                    </Button>} />
                                        </Table.Cell>
                                    </Table.Row>
                                })
                            }
                        </Table.Body>
                    </Table>
                    <Button color={"red"} onClick={() => dispatch(removeAllDevicesRoutine(user.id))}>Remove All Devices</Button>
                </div>
            }
        </Tab.Pane>
    )
}

export default DevicesTab
