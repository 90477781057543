import client from '../utils/client';
import { LatencyUpdateModel } from '../common/types/latencyUpdateModel';
import { EventValues } from '../common/types/eventValues';
import { handleError } from './../utils/client';
import env from '../env';

export const getCompetitions = async () => {
    return client.get(`${env.apiUrl}/api/schedule/competitions`).catch(handleError);
}

export const updateLatencyForEvent = (latencyUpdate: LatencyUpdateModel) => {
    return client.put(`${env.apiUrl}/api/schedule/updateLatency`, latencyUpdate).catch(handleError);
}

export const getValuesForEventsFields = () => {
    return client.get(`${env.apiUrl}/api/event_fields/`).catch(handleError);
}

export const createDisabledValueForField = (model: EventValues) => {
    return client.post(`${env.apiUrl}/api/event_fields/`, model).catch(handleError);
}
