import React, { useState, useEffect } from 'react'
import { ServerAlias } from '../../../../common/types/server-alias'
import { Modal, Form, Button } from 'semantic-ui-react';
import { createServerAliasRoutine, updateServerAliasRoutine } from '../../../../redux/routines';
import { connect } from 'react-redux';
import './server-aliases-modal.scss'
import {toastr} from "react-redux-toastr";

export interface ServerAliasesModalProps {
    existedServerAlias?: ServerAlias;
    modalOpened: boolean;
    closeModal: () => void;
    isNew: boolean;
    createServerAlias: (model) => void;
    updateServerAlias: (model) => void;
}

const ServerAliasesModal: React.FC<ServerAliasesModalProps> = ({
    modalOpened,
    closeModal,
    isNew,
    createServerAlias,
    updateServerAlias,
    existedServerAlias
}) => {

    const [hlsAlias, setHlsAlias] = useState('')
    const [rtmpAlias, setRtmpAlias] = useState('')

    useEffect(() => {
        if (existedServerAlias) {
            setHlsAlias(existedServerAlias.serverAlias)
            setRtmpAlias(existedServerAlias.rtmpServerAlias)
        }
    }, [existedServerAlias])

    const handleSubmit = () => {
        const regex = /^(?! *$).+$/

        if (!regex.test(hlsAlias) && !regex.test(rtmpAlias)) {
            toastr.error("Error!", "You have to fill at least one field.");
            return;
        }

        const model = {
            serverAlias: hlsAlias,
            rtmpServerAlias: rtmpAlias
        };

        isNew
            ? createServerAlias(model)
            : updateServerAlias({
                ...model,
                id: existedServerAlias!.id
            })
        closeModal();
    }

    return (
        <Modal
            open={modalOpened}
            onClose={closeModal}>
            <Modal.Header>
                Fill info about stream setting
            </Modal.Header>
            <Modal.Content>
                <Form.Input
                    label="HLS Alias"
                    fluid
                    onChange={(_, e) => setHlsAlias(e.value)}
                    type="text"
                    name="text"
                    placeholder="Alias"
                    value={hlsAlias}
                />
                <Form.Input
                    label="RTMP Alias"
                    fluid
                    onChange={(_, e) => setRtmpAlias(e.value)}
                    type="text"
                    name="text"
                    placeholder="Alias"
                    value={rtmpAlias}
                />
            </Modal.Content>
            <Button className="btn-submit" onClick={handleSubmit}>
                Submit
            </Button>
        </Modal>
    )
}

const mapDispatchToProps = {
    createServerAlias: createServerAliasRoutine,
    updateServerAlias: updateServerAliasRoutine
}

export default connect(null, mapDispatchToProps)(ServerAliasesModal)