import React from 'react';
import { Tab, Form } from 'semantic-ui-react';
import { Formik, FormikProps } from 'formik';
import { IDataProvidersProps } from "../../../interfaces/creation-form";

import "./styles.scss";

interface IDataProvidersTabProps {
    values: IDataProvidersProps;
    setState: (values: IDataProvidersProps) => void;
}

const DataProvidersTab: React.FC<IDataProvidersTabProps> = ({ values, setState }) => {

    const providersData = [
        {
            name: "SportRadar",
            enableFieldName: "isSPRAVEnabled",
            paywallFieldName: "isSPRAVPaywallEnabled",
            enable: values["isSPRAVEnabled"],
            paywall: values["isSPRAVPaywallEnabled"]
        },
        {
            name: "Stats Perform",
            enableFieldName: "isStatsPerformContentEnabled",
            paywallFieldName: "isStatsPerformPaywallEnabled",
            enable: values["isStatsPerformContentEnabled"],
            paywall: values["isStatsPerformPaywallEnabled"]
        },
        {
            name: "InPlay",
            enableFieldName: "isInPlayContentEnabled",
            paywallFieldName: "isInPlayPaywallEnabled",
            enable: values["isInPlayContentEnabled"],
            paywall: values["isInPlayPaywallEnabled"]
        },
        {
            name: "XVI",
            enableFieldName: "isXVIContentEnabled",
            paywallFieldName: "isXVIPaywallEnabled",
            enable: values["isXVIContentEnabled"],
            paywall: values["isXVIPaywallEnabled"]
        },
        {
            name: "Modus",
            enableFieldName: "isModusContentEnabled",
            paywallFieldName: "isModusPaywallEnabled",
            enable: values["isModusContentEnabled"],
            paywall: values["isModusPaywallEnabled"]
        },
        {
            name: "Internal Schedule",
            enableFieldName: "isInternalScheduleContentEnabled",
            paywallFieldName: "isInternalSchedulePaywallEnabled",
            enable: values["isInternalScheduleContentEnabled"],
            paywall: values["isInternalSchedulePaywallEnabled"]
        },
        {
            name: "Spring Media",
            enableFieldName: "isSpringMediaContentEnabled",
            paywallFieldName: "isSpringMediaPaywallEnabled",
            enable: values["isSpringMediaContentEnabled"],
            paywall: values["isSpringMediaPaywallEnabled"]
        },
        {
            name: "Infront",
            enableFieldName: "isInfrontContentEnabled",
            paywallFieldName: "isInfrontPaywallEnabled",
            enable: values["isInfrontContentEnabled"],
            paywall: values["isInfrontPaywallEnabled"]
        },
        {
            name: "CSM",
            enableFieldName: "isCSMContentEnabled",
            paywallFieldName: "isCSMPaywallEnabled",
            enable: values["isCSMContentEnabled"],
            paywall: values["isCSMPaywallEnabled"]
        },
        {
            name: "TDI",
            enableFieldName: "isTDIContentEnabled",
            paywallFieldName: "isTDIPaywallEnabled",
            enable: values["isTDIContentEnabled"],
            paywall: values["isTDIPaywallEnabled"]
        },
        {
            name: "Trinta",
            enableFieldName: "isTrintaContentEnabled",
            paywallFieldName: "isTrintaPaywallEnabled",
            enable: values["isTrintaContentEnabled"],
            paywall: values["isTrintaPaywallEnabled"]
        }
    ]

    return <Tab.Pane>
        <Formik key={'dataProvidersTab'}
            initialValues={values}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
            }}
            render={({ values, setFieldValue }: FormikProps<IDataProvidersProps>) => {

                const setCheckboxValue = (props: any, fieldName: string, provider) => {
                    setFieldValue(fieldName as any, props.checked)
                    values[fieldName] = props.checked;
                    if (props.checked === true) {
                        if (fieldName === provider.enableFieldName ) {
                            setFieldValue(provider.paywallFieldName as any, false)
                            values[provider.paywallFieldName] = false;
                            setState(values);
                        } else {
                            setFieldValue(provider.enableFieldName as any, false)
                            values[provider.enableFieldName] = false;
                            setState(values);
                        }
                    }
                    setState(values);
                }

                return <Form size="large">
                    <div className="providers-header">
                        <span className="providers-header__enabled">Enabled</span>
                        <span className="providers-header__paywall">Paywall</span>
                    </div>
                    {
                        providersData.map(item => {
                            return (
                                <div className="provider-section">
                                    <label>{item.name}</label>
                                    <Form.Checkbox
                                        type="checkbox"
                                        onChange={(_, props) => setCheckboxValue(props, item.enableFieldName, item)}
                                        name={item.enableFieldName}
                                        checked={values[item.enableFieldName]} />
                                    <Form.Checkbox
                                        type="checkbox"
                                        onChange={(_, props) => setCheckboxValue(props, item.paywallFieldName, item)}
                                        name={item.paywallFieldName}
                                        checked={values[item.paywallFieldName]} />
                                </div>
                            )
                        })
                    }
                </Form>
            }}
        />
    </Tab.Pane>
}

export default DataProvidersTab
