import { takeLatest, all, call, put } from "redux-saga/effects";
import {
    getServerAliases,
    createServerAliases,
    deleteServerAliases,
    updateServerAliases,
}
    from "../../services/stream-settings.service";
import {
    getServerAliasesRoutine,
    createServerAliasRoutine,
    updateServerAliasRoutine,
    deleteServerAliasRoutine
}
    from "../routines";
import { AxiosResponse } from 'axios';
import { ServerAlias } from "../../common/types/server-alias";

function* getServerAliasesCall() {
    try {
        const response: AxiosResponse<ServerAlias[]> = yield call(getServerAliases);
        yield put(getServerAliasesRoutine.success(response.data));
    } catch (err) {
        yield put(getServerAliasesRoutine.failure(err.message));
    }
}

function* createServerAliasCall(action: any) {
    try {
        const response: AxiosResponse<ServerAlias> = yield call(createServerAliases, action.payload);
        yield put(createServerAliasRoutine.success(response.data));
    } catch (err) {
        yield put(createServerAliasRoutine.failure(err.message));
    }
}

function* updateServerAliasesCall(action: any) {
    try {
        const response: AxiosResponse<ServerAlias> = yield call(updateServerAliases, action.payload);
        yield put(updateServerAliasRoutine.success(response.data));
    } catch (err) {
        yield put(updateServerAliasRoutine.failure(err.message));
    }
}

function* deleteServerAliasesCall(action: any) {
    try {
        const response: AxiosResponse = yield call(deleteServerAliases, action.payload);
        yield put(deleteServerAliasRoutine.success(response.data));
    } catch (err) {
        yield put(deleteServerAliasRoutine.failure(err.message));
    }
}



export default function* serverAliasesSaga() {
    yield all([
        takeLatest(getServerAliasesRoutine, getServerAliasesCall),
        takeLatest(createServerAliasRoutine, createServerAliasCall),
        takeLatest(updateServerAliasRoutine, updateServerAliasesCall),
        takeLatest(deleteServerAliasRoutine, deleteServerAliasesCall)
    ]);
}