import jsPDF from "jspdf";
import moment, { Moment } from "moment";
import { ReportOptions } from "../common/types/reportOptions";

export const getRolePriority = (role: string) => {
    switch (role) {
        case 'User':
            return 0;
        case 'Client Admin':
            return 1;
        case 'Admin':
            return 2;
        case 'Super Admin':
            return 3;
        default:
            throw new Error('Invalid role');
    }
};

export const getDateDiffFromNowInDays = (dt2: Date, dt1: Date) => {
    const days = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24))
    return days < 14;
}

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const numberOnly = (value: any) => typeof (value) === 'number' ? value : 0

export const savePDF = (sources: string[]) => {
    const WIDTH = 430;
    const HEIGHT = 780;
    const TOP_LEFT_MARGIN = 15;

    const pdf = new jsPDF('p', 'pt', [WIDTH, HEIGHT])
    pdf.setFontSize(16);
    pdf.text('Locations for clients', 35, 25,)

    let counter = 0;

    sources.forEach((image, i) => {
        if (i !== 0 && i % 3 === 0) {
            pdf.addPage([430, HEIGHT]);
            counter = 0;
        }
        pdf.addImage(image, 'JPG', TOP_LEFT_MARGIN, 240 * counter + 40, 400, 240)
        counter++;
    })

    pdf.save(`LocationPieCharts_${moment().format('DD/MMM/YY')}.pdf`);
}

export const SortByGroupName = (x, y) => {
    if (x.text > y.text) {
        return 1;
    }
    if (x.text < y.text) {
        return -1;
    }
    return 0;
}

export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const isSuccessfulRequest = (response): boolean => {
    return response && (response.status === 200 || response.status === 204);
}

export const millisecondsDateFromDayStart = (date) => {
    return Date.parse(moment(date).startOf("d").toString());
}

export const checkDateBeforeSet = (date, setValue) => {

    if (millisecondsDateFromDayStart(date.toDate()) === millisecondsDateFromDayStart(new Date())
        || millisecondsDateFromDayStart(date.toDate()) > millisecondsDateFromDayStart(new Date())
        || millisecondsDateFromDayStart(date.toDate()) === Date.parse(moment().startOf("d").add(-1, 'd').toString())
    ) {
        setValue(moment().startOf("d").add(-2, 'd'));
    } else {
        setValue(moment(date).startOf("d"));
    }
}
