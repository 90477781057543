import React from 'react'
import { User } from '../../../../common/types/user'
import { State } from '../../../../common/types/state'
import { connect } from 'react-redux'
import ServerAliasesTable from '../server-aliases-table/server-aliases-table'

export interface IServerAliasesPage {
    user: User;
}

const ServerAliasesPage: React.FC<IServerAliasesPage> = ({ user }) => {
    return (
        <div className="table-container">
            <h2>Server Aliases Management Page</h2>
            <ServerAliasesTable />
        </div>
    )
}

const mapStateToProps = (state: State) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(ServerAliasesPage)