import { takeLatest, all, call, put } from "redux-saga/effects";
import {
    getStreamSettings,
    createStreamSettings,
    deleteStreamSettings,
    updateStreamSettings,
}
    from "../../services/stream-settings.service";
import {
    getStreamSettingsRoutine,
    createStreamSettingRoutine,
    updateStreamSettingRoutine,
    deleteStreamSettingRoutine
}
    from "../routines";
import { AxiosResponse } from 'axios';
import { ServerAlias } from "../../common/types/server-alias";

function* getStreamSettingsCall() {
    try {
        const response: AxiosResponse<ServerAlias[]> = yield call(getStreamSettings);
        yield put(getStreamSettingsRoutine.success(response.data));
    } catch (err) {
        yield put(getStreamSettingsRoutine.failure(err.message));
    }
}

function* createStreamSettingCall(action: any) {
    try {
        const response: AxiosResponse<ServerAlias> = yield call(createStreamSettings, action.payload);
        yield put(createStreamSettingRoutine.success(response.data));
    } catch (err) {
        yield put(createStreamSettingRoutine.failure(err.message));
    }
}

function* updateStreamSettingCall(action: any) {
    try {
        const response: AxiosResponse<ServerAlias> = yield call(updateStreamSettings, action.payload);
        yield put(updateStreamSettingRoutine.success(response.data));
    } catch (err) {
        yield put(updateStreamSettingRoutine.failure(err.message));
    }
}

function* deleteStreamSettingCall(action: any) {
    try {
        const response: AxiosResponse = yield call(deleteStreamSettings, action.payload);
        yield put(deleteStreamSettingRoutine.success(response.data));
    } catch (err) {
        yield put(deleteStreamSettingRoutine.failure(err.message));
    }
}

export default function* streamSettingsSaga() {
    yield all([
        takeLatest(getStreamSettingsRoutine, getStreamSettingsCall),
        takeLatest(createStreamSettingRoutine, createStreamSettingCall),
        takeLatest(updateStreamSettingRoutine, updateStreamSettingCall),
        takeLatest(deleteStreamSettingRoutine, deleteStreamSettingCall)
    ]);
}