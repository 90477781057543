import React from 'react'
import { Modal, Header, Button } from 'semantic-ui-react';

export interface DisableConfirmationModalProps {
    modalOpened: boolean;
    closeModal: (answer: boolean) => void;
    isDisabled: boolean;
}
export const DisableConfirmationModal = (props: DisableConfirmationModalProps) => {
    const { modalOpened, closeModal, isDisabled } = props;
    return (
        <Modal
            size='small'
            open={modalOpened}
            onClose={() => closeModal(isDisabled)}>
            <Header icon='delete' content='Disable group' />
            <Modal.Content>
                <p>
                    Are you sure?
                </p>
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={() => closeModal(isDisabled)}>
                    No
                </Button>
                <Button color='black' onClick={() => closeModal(!isDisabled)}>
                    Yes
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default DisableConfirmationModal;
