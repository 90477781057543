import { call, put, all, takeLatest } from 'redux-saga/effects';
import {
    fetchUser,
    fetchRoles, fetchSports,
    fetchCountries,
    toggleIndividualRestrictions,
    resetPassword, loadingUser, toggleExternalUserRoutine
} from '../routines';
import {
    getCurrentUser, getRoles,
    switchIndividualRestrictions, forgotPassword, switchExternalUserRequest,
} from '../../services/user.service';
import { getRolePriority } from '../../utils/helpers';
import { getSports, getCountries } from '../../services/shared.service';
import { AxiosResponse } from 'axios';
import { Role } from '../../common/types/role';
import { toastr } from 'react-redux-toastr';

function* getUser() {
    try {
        yield put(loadingUser.trigger())
        const response = yield call(getCurrentUser);

        if (!response) {
            return;
        }

        yield put(fetchUser.success(response.data))
        yield put(loadingUser.success())
    } catch (err) {
        console.log(err);
        yield put(loadingUser.failure())
        yield put(fetchUser.failure(err.message));
    }
}


function* getAllSports() {
    try {
        const response = yield call(getSports);
        if (!response.data.sort()) {
            throw Error('Incorrect data');
        }
        yield put(fetchSports.success(response.data));
    } catch (err) {
        yield put(fetchSports.failure(err.message));
    }
}

function* getAllCountries() {
    try {
        const response = yield call(getCountries);
        if (!response.data.sort()) {
            throw Error('Incorrect data');
        }
        yield put(fetchCountries.success(response.data));
    } catch (err) {
        yield put(fetchCountries.failure(err.message));
    }
}

function* getAllRoles(action: any) {
    try {
        const response: AxiosResponse<Role[]> = yield call(getRoles);
        if (!response.data) {
            throw Error('Incorrect data');
        }
        const roles = response.data.filter(r => getRolePriority(r.roleName) < getRolePriority(action.payload.role))
        yield put(fetchRoles.success(roles));
    } catch (err) {
        yield put(fetchRoles.failure(err.message));
    }
}

function* switchRestrictions(action: any) {
    try {
        yield call(switchIndividualRestrictions, action.payload);

        yield put(toggleIndividualRestrictions.success());
    } catch (err) {
        yield put(toggleIndividualRestrictions.failure());
    }
}

function* switchExternalUser(action: any) {
    try {
        yield call(switchExternalUserRequest, action.payload);

        yield put(toggleExternalUserRoutine.success());
    } catch (err) {
        yield put(toggleExternalUserRoutine.failure());
    }
}

function* resetPwrd(action: any) {
    try {
        yield call(forgotPassword, action.payload);
        toastr.success('Success', 'User should receive email message with new password');
        yield put(resetPassword.success());
    } catch (err) {
        toastr.error('Error', 'Something went wrong, try again');
        yield put(resetPassword.failure());
    }
}

export default function* userSaga() {
    yield all([
        takeLatest(fetchUser, getUser),
        takeLatest(fetchRoles, getAllRoles),
        takeLatest(fetchSports, getAllSports),
        takeLatest(fetchCountries, getAllCountries),
        takeLatest(toggleIndividualRestrictions, switchRestrictions),
        takeLatest(resetPassword, resetPwrd),
        takeLatest(toggleExternalUserRoutine, switchExternalUser)
    ])
}
