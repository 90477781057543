import React, { useEffect } from 'react';
import { User } from '../../../../common/types/user';
import { State } from '../../../../common/types/state';
import env from '../../../../env';
import {useDispatch, useSelector} from 'react-redux';
import {getAnnouncementsRoutine} from "../../../../redux/routines";
import AnnouncementsTable from "../announcements-table/announcements-table";

export interface IAnnouncementsPageProps {}

const AnnouncementsPage: React.FC<IAnnouncementsPageProps> = () => {

    const user: User = useSelector((state: State) => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAnnouncementsRoutine());
    }, [])

    useEffect(() => {
        if (!user)
            window.location.href = `${env.inplayUrl}/logout`
    });

    return (
        <div className="table-container">
            <h2>Announcements Page</h2>
            <AnnouncementsTable />
        </div>
    )
}

export default AnnouncementsPage;