import React, { useState, useEffect } from 'react'
import { Modal, Form, Button } from 'semantic-ui-react';
import { ServerAlias } from '../../../../common/types/server-alias';
import Select from 'react-select';
import {
    getServerAliasesRoutine,
    createStreamSettingRoutine,
    updateStreamSettingRoutine,
    createSsoDomainRoutine, updateSsoDomainRoutine
} from '../../../../redux/routines';
import { State } from '../../../../common/types/state';
import { connect } from 'react-redux';
import { StreamSetting } from '../../../../common/types/stream-setting';
import './stream-settings-modal.scss';
import {toastr} from "react-redux-toastr";

export interface StreamSettingsModalProps {
    modalOpened: boolean;
    closeModal: () => void;
    serverAliases: ServerAlias[];
    getServerAliases: () => void;
    createStreamSetting: (model: StreamSetting) => void;
    updateStreamSetting: (model: StreamSetting) => void;
    isNew: boolean;
    existedStreamSetting?: StreamSetting;
}

const StreamSettingsModal: React.FC<StreamSettingsModalProps> = ({
    modalOpened,
    closeModal,
    serverAliases,
    getServerAliases,
    createStreamSetting,
    updateStreamSetting,
    isNew,
    existedStreamSetting
}) => {
    const [channelAlias, setChannelAlias] = useState(existedStreamSetting ? existedStreamSetting.channelAlias : '')
    const [serverAlias, setServerAlias] = useState(0 as any)

    useEffect(() => {
        getServerAliases();
    }, []);

    useEffect(() => {
        if (existedStreamSetting) {
            setChannelAlias(existedStreamSetting.channelAlias);
            setServerAlias({
                serverAliasId: existedStreamSetting.serverAliasId,
                serverAlias: `HLS: ${existedStreamSetting.serverAlias}, RTMP: ${existedStreamSetting.rtmpServerAlias}`
            });
        }
    }, [existedStreamSetting])

    const handleSubmit = () => {
        const regex = /^(?! *$).+$/

        if (!regex.test(channelAlias)) {
            toastr.error("Error!", "You can't send empty sting as channel alias value.");
            return;
        }
        if (!serverAlias.serverAlias) {
            toastr.error("Error!", "You can't send empty sting as server alias value.");
            return;
        }

        const model = {
            channelAlias: channelAlias,
            serverAliasId: serverAlias.serverAliasId,
            serverAlias: serverAlias.serverAlias
        }
        isNew
            ? createStreamSetting(model)
            : updateStreamSetting({
                ...model,
                id: existedStreamSetting?.id
            })
        closeModal();
    }

    return (
        <Modal
            open={modalOpened}
            onClose={closeModal}>
            <Modal.Header>
                Fill info about stream setting
            </Modal.Header>
            <Modal.Content>
                <Form.Input
                    label="Channel alias"
                    fluid
                    onChange={(_, e) => setChannelAlias(e.value)}
                    type="text"
                    name="text"
                    placeholder="Channel Alias"
                    value={channelAlias}
                />
                <Form.Field>
                    {
                        serverAliases && serverAliases.length &&
                        <Select
                            placeholder='Server Alias'
                            onChange={(alias: any) => {
                                setServerAlias({
                                    serverAlias: alias.label,
                                    serverAliasId: alias.value
                                });
                            }}
                            value={{
                                label: serverAlias.serverAlias,
                                value: serverAlias.serverAliasId
                            }}
                            options={serverAliases.map((s: ServerAlias) => {
                                return {
                                    value: s.id,
                                    label: `HLS: ${s.serverAlias}, RTMP: ${s.rtmpServerAlias}`
                                }
                            })}
                        />
                    }

                </Form.Field>
            </Modal.Content>
            <div className="btn-container">
                <Button className="btn-submit" onClick={handleSubmit}>
                    Submit
                </Button>
            </div>
        </Modal>
    )
}

const mapDispathToProps = {
    getServerAliases: getServerAliasesRoutine,
    createStreamSetting: createStreamSettingRoutine,
    updateStreamSetting: updateStreamSettingRoutine
}

const mapStateToProps = (state: State) => {
    return {
        serverAliases: state.serverAliases
    }
}

export default connect(mapStateToProps, mapDispathToProps)(StreamSettingsModal)
