import React, {useEffect, useState} from "react";
import {SingleDatePicker} from "react-dates";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "./styles.scss";
import moment, {Moment} from "moment";
import { Modal, Button } from 'semantic-ui-react';
import {useDispatch} from "react-redux";
import {IAnnouncement} from "../../../../common/types/announcements";
import {createAnnouncementRoutine, updateAnnouncementRoutine} from "../../../../redux/routines";
import {toastr} from "react-redux-toastr";

export interface IFocusState {
    focused: boolean | null;
}

export interface IAnnouncementModalProps {
    modalOpened: boolean;
    closeModal: () => void;
    announcementToUpdate: IAnnouncement | null;
    refreshForm: () => void;
    announcements: IAnnouncement[];
}

export interface IAnnouncementFormValues {
    id: number;
    title: string;
    body: string;
    releaseFrom: Moment;
}

const AnnouncementModal: React.FunctionComponent<IAnnouncementModalProps> = ({
    modalOpened,
    closeModal,
    announcementToUpdate,
    refreshForm,
}) => {

    const [focusedInput, setFocusedInput] = useState<IFocusState>({focused: false});
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");
    const [releaseFrom, setReleaseFrom] = useState(moment());
    const dispatch = useDispatch();

    const link = Quill.import('formats/link');
    link.sanitize = (url) => {
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            return `http://${url}`
        }
        return url;
    }

    useEffect(() => {
        if (announcementToUpdate !== null) {
            setTitle(announcementToUpdate!.title);
            setBody(announcementToUpdate!.body);
            setReleaseFrom(moment(announcementToUpdate.releaseFrom));
        }
    }, [announcementToUpdate]);

    const handleDateSelection = (dateValue) => {
        setReleaseFrom(dateValue);
    }

    const handleSubmit = () => {
        const model = {
            title,
            body,
            releaseFrom
        }

        if (!title || !body) {
            toastr.error("Error", "All fields are required");
            return
        } else {
            if(announcementToUpdate === null) {
                dispatch(createAnnouncementRoutine({model}));
            } else {
                dispatch(updateAnnouncementRoutine({
                    id: announcementToUpdate?.id,
                    model
                }));
            }
        }
        handleClose();
    }

    const handleClose = () => {
        setTitle("");
        setBody("");
        setReleaseFrom(moment());
        refreshForm();
        closeModal();
    }

    return (
        <Modal
            open={modalOpened}
            onClose={closeModal}>
            <Modal.Header>
                {
                    announcementToUpdate ? "Update Announcement" : "Create New Announcement"
                }
            </Modal.Header>
            <Modal.Content>
                <form className="announcement-modal__form" onSubmit={handleSubmit}>
                    <div className="announcement-modal__form-time">
                        <label htmlFor="time">
                            Release communications from
                        </label>
                        <SingleDatePicker
                            displayFormat={() => "DD/MM/YYYY"}
                            date={releaseFrom}
                            onDateChange={(dateValue) => handleDateSelection(dateValue)}
                            focused={focusedInput.focused}
                            onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                            id="time"
                        />
                    </div>
                    <label htmlFor="title">
                        Title
                    </label>
                    <input className="announcement-modal__form-input"
                           type="text"
                           id="title"
                           value={title}
                           onChange={(e) => setTitle( e.currentTarget.value)}
                    />
                    <label htmlFor="body">
                        Body*
                    </label>
                    <ReactQuill className="announcement-modal__form-editor"
                                theme="snow"
                                value={body}
                                onChange={(value) => setBody(value)}
                                readOnly={false}
                    />
                    <p className="announcement-modal__form-tip">*Double click on chosen option on the editor panel to undo text selection</p>
                </form>
            </Modal.Content>
            <Modal.Actions>
                <Button className="confirm-button" type='submit' color="green" onClick={handleSubmit}>
                    Confirm
                </Button>
                <Button className="cancel-button" type='button' color="red" onClick={handleClose}>
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default AnnouncementModal;