import { Routine } from 'redux-saga-routines';
import { getStreamSettingsRoutine, createStreamSettingRoutine, deleteStreamSettingRoutine, updateStreamSettingRoutine } from '../../routines';
import { StreamSetting } from '../../../common/types/stream-setting';

export const streamSettingsReducer = (state: StreamSetting[] | null = null, action: Routine<any>) => {
    switch (action.type) {
        case getStreamSettingsRoutine.SUCCESS:
            return action.payload;

        case createStreamSettingRoutine.SUCCESS:
            const newState = state!.filter(i => i.id !== action.payload.id)
            return [
                ...newState,
                action.payload
            ]
        case deleteStreamSettingRoutine.TRIGGER:
            return [
                ...(state!.filter(i => i.id !== action.payload))
            ]
        case updateStreamSettingRoutine.SUCCESS:
            const indexUpdated = state!.findIndex(i => i.id === action.payload.id);
            const updatedState = state;
            updatedState![indexUpdated as number] = action.payload;

            return [...updatedState as StreamSetting[]];
        default:
            return state;
    }
}


