import React  from 'react';
import { Modal, Header, Button } from 'semantic-ui-react';
import {useDispatch} from "react-redux";
import {removeGroupDevicesRoutine} from "../../../../redux/routines";

import "./styles.scss";

export interface RemoveGroupDevicesModalProps {
    modalOpened: boolean;
    closeModal: () => void;
    groupId: number;
}

export const RemoveGroupDevicesModal: React.FC<RemoveGroupDevicesModalProps> = ({
    modalOpened,
    closeModal,
    groupId
    }) => {

    const dispatch = useDispatch();

    const removeGroupDevices = () => {
        dispatch(removeGroupDevicesRoutine(groupId));
        closeModal();
    }

    return (
        <Modal
            size='small'
            open={modalOpened}
            onClose={closeModal}>
            <Header icon='delete' content="Remove All Devices" />
            <Modal.Content>
                <p>
                    Are you sure you wish to clear all users’ devices?
                </p>
            </Modal.Content>
            <Modal.Actions>
                <div className="remove-devices-buttons">
                    <Button color='green' onClick={removeGroupDevices}>
                        Confirm
                    </Button>
                    <Button color='red' onClick={closeModal}>
                        Cancel
                    </Button>
                </div>
            </Modal.Actions>
        </Modal>
    )
}
