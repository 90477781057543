import React from 'react';
import {Tab, Form, Input} from 'semantic-ui-react';
import { Formik, FormikProps } from 'formik';
import { IStreamingProps } from "../../../interfaces/creation-form";

import "./styles.scss";

interface IStreamingTabProps {
    values: any;
    setState: (values: IStreamingProps) => void;
}

const StreamingTab: React.FC<IStreamingTabProps> = ({ values, setState }) => {

    const providersData = [
        {
            name: "HD Live",
            enableFieldName: "isHdPlayerEnabled",
            paywallFieldName: "isHdPlayerPaywallEnabled",
            enable: values["isHdPlayerEnabled"],
            paywall: values["isHdPlayerPaywallEnabled"]
        },
        {
            name: "SD Live",
            enableFieldName: "isSdPlayerEnabled",
            paywallFieldName: "isSdPlayerPaywallEnabled",
            enable: values["isSdPlayerEnabled"],
            paywall: values["isSdPlayerPaywallEnabled"]
        },
        {
            name: "HD Review",
            enableFieldName: "isHdHlsEnabled",
            paywallFieldName: "isHdHlsPaywallEnabled",
            enable: values["isHdHlsEnabled"],
            paywall: values["isHdHlsPaywallEnabled"]
        },
        {
            name: "SD Review",
            enableFieldName: "isSdHlsEnabled",
            paywallFieldName: "isSdHlsPaywallEnabled",
            enable: values["isSdHlsEnabled"],
            paywall: values["isSdHlsPaywallEnabled"]
        },
        {
            name: "HD VOD",
            enableFieldName: "isHdVodEnabled",
            paywallFieldName: "isHdVodPaywallEnabled",
            enable: values["isHdVodEnabled"],
            paywall: values["isHdVodPaywallEnabled"]
        },
        {
            name: "SD VOD",
            enableFieldName: "isSdVodEnabled",
            paywallFieldName: "isSdVodPaywallEnabled",
            enable: values["isSdVodEnabled"],
            paywall: values["isSdVodPaywallEnabled"]
        },
    ]

    return <Tab.Pane>
        <Formik key={'streamingTab'}
                initialValues={values}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                }}
                render={({ values, setFieldValue }: FormikProps<IStreamingProps>) => {

                    const setCheckboxValue = (props: any, fieldName: string, provider) => {
                        setFieldValue(fieldName as any, props.checked)
                        values[fieldName] = props.checked;
                        if (props.checked === true) {
                            if (fieldName === provider.enableFieldName ) {
                                setFieldValue(provider.paywallFieldName as any, false)
                                values[provider.paywallFieldName] = false;
                                setState(values);
                            } else {
                                setFieldValue(provider.enableFieldName as any, false)
                                values[provider.enableFieldName] = false;
                                setState(values);
                            }
                        }
                        setState(values);
                    }

                    const setInputValue = (data, fieldName) => {
                        values[fieldName] = data.value;
                        setState(values);
                    }

                    return <Form size="large">
                        <div className="streams-header">
                            <span className="streams-header__enabled">Enabled</span>
                            <span className="streams-header__paywall">Paywall</span>
                        </div>
                        {
                            providersData.map(item => {
                                return (
                                    <div className="stream-type-section">
                                        <label>{item.name}</label>
                                        <Form.Checkbox
                                            type="checkbox"
                                            onChange={(_, props) => setCheckboxValue(props, item.enableFieldName, item)}
                                            name={item.enableFieldName}
                                            checked={values[item.enableFieldName]} />
                                        <Form.Checkbox
                                            type="checkbox"
                                            onChange={(_, props) => setCheckboxValue(props, item.paywallFieldName, item)}
                                            name={item.paywallFieldName}
                                            checked={values[item.paywallFieldName]} />
                                    </div>
                                )
                            })
                        }
                        <div className="text-inputs-section">
                            <Form.Field inline>
                                <Input
                                    type="text"
                                    label={'Buffering (ms)'}
                                    name="buffering"
                                    placeholder="Enter value"
                                    value={values.bufferingMs}
                                    fluid
                                    inline={1}
                                    size="mini"
                                    key={'email'}
                                    onChange={(_, data) => setInputValue(data, 'bufferingMs')}
                                />
                            </Form.Field>
                            <Form.Field inline>
                                <Input
                                    type="text"
                                    label={'Latency Tolerance (ms)'}
                                    name="latencyTolerance"
                                    placeholder="Enter value"
                                    value={values.latencyToleranceMs}
                                    fluid
                                    inline={1}
                                    size="mini"
                                    key={'email'}
                                    onChange={(_, data) => setInputValue(data, 'latencyToleranceMs')}
                                />
                            </Form.Field>
                        </div>
                    </Form>
                }}
        />
    </Tab.Pane>
}

export default StreamingTab;
