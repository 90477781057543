import React from 'react'
import { Icon } from 'semantic-ui-react';
import '../styles.scss'
import { User } from '../../../common/types/user';

export interface MenuOptionProps {
    onClick: (params) => void;
    iconName: any,
    text: string,
    level: number
    roles: string[],
    user: User,
    isOpened: boolean
}

const MenuOption: React.FC<MenuOptionProps> = ({ level, iconName, onClick, text, user, roles, isOpened }) => {

    return roles.includes(user.role) && isOpened ? (
        <React.Fragment>
            <li className="menu-item" onClick={onClick} style={{ marginLeft: `${level * 20}px` }}>
                <Icon name={iconName} />
                <p>{text}</p>
            </li>
        </React.Fragment>
    ) : <React.Fragment />

}

export default MenuOption;