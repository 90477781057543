import { all, fork } from 'redux-saga/effects';
import userSaga from './sagas/userSaga';
import schedulesSaga from './sagas/shedulesSaga';
import groupsSaga from './sagas/groupsSaga';
import statisticsSaga from './sagas/statisticsSaga';
import streamSettingsSaga from './sagas/streamSettingsSaga';
import serverAliasesSaga from './sagas/serverAliasesSage';
import deviceSaga from "./sagas/deviceSaga";
import ssoSaga from "./sagas/ssoSaga";
import announcementsSaga from "./sagas/announcementsSaga";

export default function* rootSaga() {
    yield all(
        [fork(userSaga),
        fork(schedulesSaga),
        fork(groupsSaga),
        fork(statisticsSaga),
        fork(streamSettingsSaga),
        fork(serverAliasesSaga),
        fork(deviceSaga),
        fork(ssoSaga),
        fork(announcementsSaga),])
}
