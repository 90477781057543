import client from "../utils/client";
import env from "../env";
import moment, { Moment } from 'moment';

export const getContractSpecData = (startDate: Moment, endDate: Moment) => {
    return client.get(`${env.apiUrl}/api/statistics/contract?startDate=${startDate}&endDate=${endDate}`)
}

export const getContractSpecDataForGroup = (groupId: number, startDate: Moment, endDate: Moment) => {
    return client.get(`${env.apiUrl}/api/statistics/contract/${groupId}?startDate=${startDate}&endDate=${endDate}`)
}

export const downloadNewDetailedEventsCsv = (groupId: number, startDate: string, endDate: string) => {
    return client.get(`${env.apiUrl}/api/statistics/contract/events/${groupId}/download?startDate=${startDate}&endDate=${endDate}`)
}

export const getStatisticsTableData = (startDate: Moment, endDate: Moment) => {
    return client.get(`${env.apiUrl}/api/statistics/summary?startDate=${startDate}&endDate=${endDate}`)
}

export const getStatisticsTableDataForGroup = (groupId: number, startDate: Moment, endDate: Moment) => {
    return client.get(`${env.apiUrl}/api/statistics/summary/${groupId}?startDate=${startDate}&endDate=${endDate}`)
}

export const downloadGroupSummaryCsv = (groupId: number, startDate: string, endDate: string) => {
    return client.get(`${env.apiUrl}/api/statistics/summary/download/${groupId}?startDate=${startDate}&endDate=${endDate}`)
}

export const downloadSummaryCsv = (startDate: string, endDate: string) => {
    return client.get(`${env.apiUrl}/api/statistics/summary/download?startDate=${startDate}&endDate=${endDate}`)
}

export const getUsersData = (startDate: Moment, endDate: Moment) => {
    return client.get(`${env.apiUrl}/api/statistics/detailed/users?startDate=${startDate}&endDate=${endDate}`)
}

export const getUsersDataForGroup = (groupId: number, startDate: Moment, endDate: Moment) => {
    return client.get(`${env.apiUrl}/api/statistics/detailed/${groupId}/users?startDate=${startDate}&endDate=${endDate}`)
}

export const getSessionsData = (startDate: Moment, endDate: Moment) => {
    return client.get(`${env.apiUrl}/api/statistics/detailed/sessions?startDate=${startDate}&endDate=${endDate}`)
}

export const getSessionsDataForGroup = (groupId: number, startDate: Moment, endDate: Moment) => {
    return client.get(`${env.apiUrl}/api/statistics/detailed/${groupId}/sessions?startDate=${startDate}&endDate=${endDate}`)
}

export const getSessionsByDeviceData = (startDate: Moment, endDate: Moment) => {
    return client.get(`${env.apiUrl}/api/statistics/detailed/devices?startDate=${startDate}&endDate=${endDate}`)
}

export const getSessionsByDeviceDataForGroup = (groupId: number, startDate: Moment, endDate: Moment) => {
    return client.get(`${env.apiUrl}/api/statistics/detailed/${groupId}/devices?startDate=${startDate}&endDate=${endDate}`)
}

export const getTotalDurationData = (startDate: Moment, endDate: Moment) => {
    return client.get(`${env.apiUrl}/api/statistics/detailed/duration?startDate=${startDate}&endDate=${endDate}`)
}

export const getTotalDurationDataForGroup = (groupId: number, startDate: Moment, endDate: Moment) => {
    return client.get(`${env.apiUrl}/api/statistics/detailed/${groupId}/duration?startDate=${startDate}&endDate=${endDate}`)
}

export const getStreamFormatData = (startDate: Moment, endDate: Moment) => {
    return client.get(`${env.apiUrl}/api/statistics/detailed/stream-format?startDate=${startDate}&endDate=${endDate}`)
}

export const getStreamFormatDataForGroup = (groupId: number, startDate: Moment, endDate: Moment) => {
    return client.get(`${env.apiUrl}/api/statistics/detailed/${groupId}/stream-format?startDate=${startDate}&endDate=${endDate}`)
}

export const getStreamFormatDurationData = (startDate: Moment, endDate: Moment) => {
    return client.get(`${env.apiUrl}/api/statistics/detailed/stream-format-duration?startDate=${startDate}&endDate=${endDate}`)
}

export const getStreamFormatDurationDataForGroup = (groupId: number, startDate: Moment, endDate: Moment) => {
    return client.get(`${env.apiUrl}/api/statistics/detailed/${groupId}/stream-format-duration?startDate=${startDate}&endDate=${endDate}`)
}

export const getSportsData = (startDate: Moment, endDate: Moment) => {
    return client.get(`${env.apiUrl}/api/statistics/detailed/sports?startDate=${startDate}&endDate=${endDate}`)
}

export const getSportsDataForGroup = (groupId: number, startDate: Moment, endDate: Moment) => {
    return client.get(`${env.apiUrl}/api/statistics/detailed/${groupId}/sports?startDate=${startDate}&endDate=${endDate}`)
}

export const getAverageScreensData = (startDate: Moment, endDate: Moment) => {
    return client.get(`${env.apiUrl}/api/statistics/detailed/average-screens?startDate=${startDate}&endDate=${endDate}`)
}

export const getAverageScreensDataForGroup = (groupId: number, startDate: Moment, endDate: Moment) => {
    return client.get(`${env.apiUrl}/api/statistics/detailed/${groupId}/average-screens?startDate=${startDate}&endDate=${endDate}`)
}

export const getLocationsData = (startDate: Moment, endDate: Moment) => {
    return client.get(`${env.apiUrl}/api/statistics/detailed/locations?startDate=${startDate}&endDate=${endDate}`)
}

export const getLocationsDataForGroup = (groupId: number, startDate: Moment, endDate: Moment) => {
    return client.get(`${env.apiUrl}/api/statistics/detailed/${groupId}/locations?startDate=${startDate}&endDate=${endDate}`)
}

export const getDownloadsData = (startDate: Moment, endDate: Moment) => {
    return client.get(`${env.apiUrl}/api/statistics/detailed/downloads?startDate=${startDate}&endDate=${endDate}`)
}

export const getDownloadsDataForGroup = (groupId: number, startDate: Moment, endDate: Moment) => {
    return client.get(`${env.apiUrl}/api/statistics/detailed/${groupId}/downloads?startDate=${startDate}&endDate=${endDate}`)
}

export const getEventsData = (startDate: Moment, endDate: Moment) => {
    return client.get(`${env.apiUrl}/api/statistics/detailed/events?startDate=${startDate}&endDate=${endDate}`)
}

export const getEventsDataForGroup = (groupId: number, startDate: Moment, endDate: Moment) => {
    return client.get(`${env.apiUrl}/api/statistics/detailed/${groupId}/events?startDate=${startDate}&endDate=${endDate}`)
}

export const getContentProvidersData = (startDate: Moment, endDate: Moment) => {
    return client.get(`${env.apiUrl}/api/statistics/detailed/content-providers?startDate=${startDate}&endDate=${endDate}`)
}

export const getContentProvidersDataForGroup = (groupId: number, startDate: Moment, endDate: Moment) => {
    return client.get(`${env.apiUrl}/api/statistics/detailed/${groupId}/content-providers?startDate=${startDate}&endDate=${endDate}`)
}

export const downloadDetailsCsv = async (data: any, name, startDate: string, endDate: string) => {
    const response = await client.post(`${env.apiUrl}/api/statistics/detailed/${name}/download?startDate=${startDate}&endDate=${endDate}`, data, { responseType: 'blob' })
    if (navigator.msSaveBlob) {
        // Foe IE and Edge purposes
        return navigator.msSaveBlob(response.data, `${moment(startDate)}-${moment(endDate)}.csv`);
    }

    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${'Logs_' + moment(startDate).format('YYYY-MM-DD').toString() + "-" + moment(endDate).format('YYYY-MM-DD').toString()}.csv`);
    document.body.appendChild(link);
    link.click();
}





