import React from 'react';
import './styles.scss';
import { Icon } from 'semantic-ui-react';
import './styles.scss'
import { User } from '../../common/types/user';
import { connect } from 'react-redux';
import env from '../../env';
import { State } from '../../common/types/state';

export interface HeaderProps {
    user: User;
}

const Header: React.FC<HeaderProps> = ({ user }) => {

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
    }

    return (
        <div className="main-header">
            <div className="user-info">
                <Icon name="user" size='large' />
                {
                    user ? <p>{user.username}</p> : <p>Unauthorized</p>
                }
            </div>
            <div className="actions">
                <a title="Inplay" className="inplay-link" href={`${env.inplayUrl}/schedule`} onClick={logout}>
                    <Icon name='tv' size='large' />
                </a>
            </div>
        </div>
    )
}

const mapStateToProps = (state: State) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(Header)
