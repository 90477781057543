import client from "../utils/client"
import env from "../env";
import { StreamSetting } from "../common/types/stream-setting";
import { ServerAlias } from "../common/types/server-alias";

export const getStreamSettings = () => {
    return client.get(`${env.apiUrl}/api/streamsettings/`);
}

export const getServerAliases = () => {
    return client.get(`${env.apiUrl}/api/streamsettings/server_alias`);
}

export const createStreamSettings = (model: StreamSetting) => {
    return client.post(`${env.apiUrl}/api/streamsettings/`, model);
}

export const createServerAliases = (model: ServerAlias) => {
    return client.post(`${env.apiUrl}/api/streamsettings/server_alias`, model);
}

export const updateStreamSettings = (model: StreamSetting) => {
    return client.put(`${env.apiUrl}/api/streamsettings/${model.id}`, model);
}

export const updateServerAliases = (model: ServerAlias) => {
    return client.put(`${env.apiUrl}/api/streamsettings/server_alias/${model.id}`, model);
}

export const deleteStreamSettings = (id: number) => {
    return client.delete(`${env.apiUrl}/api/streamsettings/${id}`);
}

export const deleteServerAliases = (id: number) => {
    return client.delete(`${env.apiUrl}/api/streamsettings/server_alias/${id}`);
}