import { Routine } from "redux-saga-routines";
import { getCountOfDistinctEventsForTiersRoutine } from "../../routines";

export const eventsCountReducer = (state = 0, action: Routine<any>) => {
    switch (action.type) {
        case getCountOfDistinctEventsForTiersRoutine.SUCCESS:
            return action.payload;
        default:
            return state;
    }
}