import { Routine } from 'redux-saga-routines';
import { loadingUser } from '../../routines';

export const loadingReducer = (state = true, action: Routine<any>) => {
    switch (action.type) {
        case loadingUser.TRIGGER:
            return true
        case loadingUser.SUCCESS:
            return false
        case loadingUser.FAILURE:
            return false
        default:
            return state;
    }
}