import client, {handleError} from "../utils/client";
import env from "../env";
import {SsoDomain} from "../common/types/sso";

export const getSsoDomainsRequest = () => {
    return client.get(`${env.apiUrl}/api/whitelist-domains`).catch(handleError);
}

export const createSsoDomainRequest = (newDomain : {domain: string}) => {
    return client.post(`${env.apiUrl}/api/whitelist-domains`, newDomain).catch(handleError);
}

export const updateSsoDomainRequest = (model: SsoDomain) => {
    return client.put(`${env.apiUrl}/api/whitelist-domains`, model).catch(handleError);
}

export const deleteSsoDomainRequest = (id: string) => {
    return client.delete(`${env.apiUrl}/api/whitelist-domains/${id}`).catch(handleError);
}
