import React, {useEffect, useState} from "react";
import SideStatsControls from "./side-stats-controls/side-stats-controls";
import moment, {Moment} from "moment";
import StatisticsCharts from "./statistics-charts/statistics-charts";
import {ChartType} from "../enums/chart-type";
import {DateType} from "../enums/date-type"
import {Paths} from "../enums/paths";
import "./styles.scss";
import {ClientsDropdown} from "../clients-dropdown/clients-dropdown";
import {Button} from "semantic-ui-react";
import {DateRangePicker, isInclusivelyAfterDay} from "react-dates";
import {Role} from "../../../router/role";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../../common/types/state";
import {
    getAverageScreensDataForGroupRoutine,
    getAverageScreensDataRoutine,
    getContentProvidersDataForGroupRoutine,
    getContentProvidersDataRoutine,
    getDownloadsDataForGroupRoutine,
    getDownloadsDataRoutine,
    getEventsDataForGroupRoutine,
    getEventsDataRoutine,
    getLocationsDataForGroupRoutine,
    getLocationsDataRoutine,
    getSessionsByDeviceDataForGroupRoutine,
    getSessionsByDeviceDataRoutine,
    getSessionsDataForGroupRoutine,
    getSessionsDataRoutine,
    getSportsDataForGroupRoutine,
    getSportsDataRoutine,
    getStreamFormatDataForGroupRoutine,
    getStreamFormatDataRoutine,
    getStreamFormatDurationDataForGroupRoutine,
    getStreamFormatDurationDataRoutine,
    getTotalDurationDataForGroupRoutine,
    getTotalDurationDataRoutine,
    getUsersDataForGroupRoutine,
    getUsersDataRoutine
} from "../../../../redux/routines";
import SystemOverloadPopup from "./system-overload-popup/system-overload-popup";
import {downloadDetailsCsv} from "../../../../services/statistics.service";
import {capitalize, checkDateBeforeSet, savePDF} from "../../../../utils/helpers";
import {toastr} from "react-redux-toastr";
import html2canvas from "html2canvas";
export interface INewStatisticsChartsPageProps {}

export interface INestedData {
    name: string;
    value: string;
}

export interface IChartData {
    type: string;
    caption: string;
    traces: any;
    columnSubtitle: string;
    scaleSubtitle: string;
    dataField: any;
    nestedData?: INestedData;
    dataSet: any[];
    isLineChartsRequired: boolean;
    isStacked: boolean;
    lineChartDateType: string;
    multiChart?: boolean;
}

export interface IPossibleColumn {
    key: string;
    text: string;
    value: string;
}

const NewStatisticsChartsPage: React.FC<INewStatisticsChartsPageProps> = () => {
    const [chartData, setChartData] = useState<IChartData>({
        type: 'BarChart',
        caption: 'Users',
        traces: 'Users',
        columnSubtitle: 'Client',
        scaleSubtitle: 'Users count',
        dataField: 'usersCount',
        dataSet: [],
        isLineChartsRequired: true,
        isStacked: false,
        lineChartDateType: DateType.hours,
    });

    const [startDate, setStartDate] = useState(moment().startOf("d").add(-9, 'd') as Moment);
    const [endDate, setEndDate] = useState(moment().startOf("d").add(-2, 'd') as Moment);
    const [focusedInput, setFocusedInput] = useState();
    const [columns, setColumns] = useState<string[]>([]);
    const [possibleColumns, setPossibleColumns] = useState<IPossibleColumn[]>([]);
    const [filteredLogs, setFilteredLogs] = useState([] as any);
    const [nestedTraces, setNestedTraces] = useState([]);
    const [uniqueDates, setUniqueDates] = useState<string[]>([]);
    const [bigScopeOfData, setBigScopeOfData] = useState(false);
    const [systemOverloadPopupVisible, setSystemOverloadPopupVisible] = useState(false);
    const [csvData, setCsvData] = useState();
    const [chartImages, setChartImages] = useState([]);

    const user = useSelector((state: State) => state.user);
    const logs = useSelector((state: State) => state.statistics.detailsLogs.source);
    const dispatch = useDispatch();

    const simpleLogs = chartData!.caption === 'Users' || chartData!.caption === 'Sessions' || chartData!.caption === 'Total Duration'
        || chartData!.caption === 'Average Screens' || chartData!.caption === 'Downloads' || chartData!.caption === 'Distinct Events';

    const multiLogs = chartData!.caption === 'Devices' || chartData!.caption === 'Stream Format' || chartData!.caption === 'Duration By Stream Format';

    const complexLogs = chartData!.caption === 'Sports' || chartData!.caption === 'Content Providers';

    useEffect(() => {
        checkCaptionForRequest(chartData!.caption);
    }, [chartData.caption, endDate, startDate]);

    useEffect(() => {
        const millisecondsInOneDay = 24 * 60 * 60 * 1000;
        const daysDiff = Math.ceil((Date.parse(endDate.toString()) - Date.parse(startDate.toString())) / millisecondsInOneDay);
        if(daysDiff > 30) {
            setBigScopeOfData(true);
            setSystemOverloadPopupVisible(true);
            if (chartData.type === ChartType.LineChart) {
                setChartData({...chartData, lineChartDateType: DateType.days});
            }
        } else {
            setBigScopeOfData(false);
        }
    }, [endDate, startDate]);

    useEffect(() => {
        const columnNames = logs.map((client: any) => client.clientName);
        const defaultClient = columnNames.find(x => x === user.group) || "";
        const possibleColumns = columnNames.map((x: string) => ({ key: x, text: x, value: x }));

        setColumns([defaultClient]);
        setPossibleColumns(possibleColumns);

        if(chartData.caption === 'Sports' ||  chartData.caption === 'Content Providers') {
            const allPossibleOptions = logs.reduce((acc: any[], val: any) => {
                const options = val[chartData.dataField] && val[chartData.dataField].map(x => x[chartData.nestedData!.name]);
                if (Array.isArray(options)) {
                    return [...acc, ...options];
                } else {
                    return [];
                }
            },[])
            const uniqueTraces: any = Array.from(new Set(allPossibleOptions)).sort();
            setNestedTraces(uniqueTraces);
        }
    }, [logs, chartData.type]);

    useEffect(() => {
        const filteredData = logs.filter((client: any ) => columns.includes(client.clientName));
        setFilteredLogs(filteredData);
    }, [columns]);

    useEffect(() => {
        checkCaptionForDataGenerating();
    }, [filteredLogs, uniqueDates]);

    useEffect(() => {
        if (chartData.type === ChartType.LineChart) {
            if (filteredLogs.length !== 0) {
                const dates = filteredLogs[0][chartData!.lineChartDateType].map(x => x.date).sort();
                setUniqueDates(dates);
            }
        }
    }, [chartData.lineChartDateType, chartData.type, filteredLogs]);

    const checkCaptionForRequest = (caption) => {
        switch (caption) {
            case 'Users':
                checkRoleAndSendRequest(getUsersDataForGroupRoutine, getUsersDataRoutine);
                return;
            case 'Sessions':
                checkRoleAndSendRequest(getSessionsDataForGroupRoutine, getSessionsDataRoutine);
                return;
            case 'Devices':
                checkRoleAndSendRequest(getSessionsByDeviceDataForGroupRoutine, getSessionsByDeviceDataRoutine);
                return;
            case 'Total Duration':
                checkRoleAndSendRequest(getTotalDurationDataForGroupRoutine, getTotalDurationDataRoutine);
                return;
            case 'Stream Format':
                checkRoleAndSendRequest(getStreamFormatDataForGroupRoutine, getStreamFormatDataRoutine);
                return;
            case 'Duration By Stream Format':
                checkRoleAndSendRequest(getStreamFormatDurationDataForGroupRoutine, getStreamFormatDurationDataRoutine);
                return;
            case 'Sports':
                checkRoleAndSendRequest(getSportsDataForGroupRoutine, getSportsDataRoutine);
                return;
            case 'Average Screens':
                checkRoleAndSendRequest(getAverageScreensDataForGroupRoutine, getAverageScreensDataRoutine);
                return;
            case 'Locations':
            case 'Locations (for each client)':
                checkRoleAndSendRequest(getLocationsDataForGroupRoutine, getLocationsDataRoutine);
                return;
            case 'Downloads':
                checkRoleAndSendRequest(getDownloadsDataForGroupRoutine, getDownloadsDataRoutine);
                return;
            case 'Distinct Events':
                checkRoleAndSendRequest(getEventsDataForGroupRoutine, getEventsDataRoutine);
                return;
            case 'Content Providers':
                checkRoleAndSendRequest(getContentProvidersDataForGroupRoutine, getContentProvidersDataRoutine);
                return;
        }
    }

    const checkCaptionForDataGenerating = () => {
        if (chartData.type === ChartType.BarChart) {
            if (simpleLogs) {
                generateBarChartDataFromSimpleLogs();
            } else if (multiLogs) {
                generateBarChartDataFromMultiLogs();
            } else if (complexLogs) {
                generateBarChartDataFromComplexLogs();
            }
        } else if (chartData.type === ChartType.LineChart) {
            if (simpleLogs) {
                generateLineChartDataFromSimpleLogs();
            } else if (multiLogs) {
                generateLineChartDataFromMultiLogs();
            } else if (complexLogs) {
                generateLineChartDataFromComplexLogs();
            }
        } else if (chartData.type === ChartType.GeoChart){
            generateChartDataFromLocationLogs();
        } else {
            generateChartDataForEachClientFromLocationLogs();
        }
    }

    const checkRoleAndSendRequest = (getOnlyForGroupRequest, getAllClientsRequest) => {
        if ([Role.ClientAdmin].includes(user!.role)) {
            dispatch(getOnlyForGroupRequest({
                groupId: user!.groupId,
                startDate: startDate.clone().format("YYYY-MM-DDTHH:mm:ss"),
                endDate: endDate.clone().add(1, 'd').format("YYYY-MM-DDTHH:mm:ss")
            }));
        }
        if ([Role.Admin, Role.SuperAdmin].includes(user!.role)) {
            dispatch(getAllClientsRequest({
                startDate: startDate.clone().format("YYYY-MM-DDTHH:mm:ss"),
                endDate: endDate.clone().add(1, 'd').format("YYYY-MM-DDTHH:mm:ss")
            }));
        }
    }

    const generateBarChartDataFromSimpleLogs = () => {
        const axis = [chartData.columnSubtitle, chartData.traces];
        const logsData = filteredLogs.map((item: any) => {
            return [item.clientName, item[chartData.dataField]]
        })
        const barChartData: any = [axis, ...logsData];
        setChartData({...chartData, dataSet: barChartData});

        const fileData = filteredLogs.map(client => {
            return {
                properties: [
                    {
                        propertyKey: 'client',
                        propertyValue: client.clientName
                    },
                    {
                        propertyKey: capitalize(chartData.dataField),
                        propertyValue: `${client[chartData.dataField] && client[chartData.dataField].toString()}`
                    }
                ]
            }
        })
        setCsvData(fileData);
    }

    const generateBarChartDataFromMultiLogs = () => {
        const axis = [chartData.columnSubtitle, ...chartData.traces];
        const logsData = filteredLogs.map((item: any) => {
            const data = chartData.dataField.map(val => item[val]);
            return [item.clientName, ...data];
        })
        const barChartData: any = [axis, ...logsData];
        setChartData({...chartData, dataSet: barChartData});

        const fileData = filteredLogs.map(client => {
            const tracesData = chartData.dataField.map(x => {
                return {
                    propertyKey: capitalize(x),
                    propertyValue: `${client[x] && client[x].toString()}`
                }
            })
            return {
                properties: [
                    {
                        propertyKey: 'client',
                        propertyValue: client.clientName
                    },
                    ...tracesData
                ]
            }
        })
        setCsvData(fileData);
    }

    const generateBarChartDataFromComplexLogs = () => {
        const axis = [chartData.columnSubtitle, ...nestedTraces];
        const logsData = filteredLogs.map((item: any) => {
            const dataObj = item[chartData.dataField] && item[chartData.dataField].reduce((acc, x) => {
                return {
                    ...acc,
                    [x[chartData.nestedData!.name]] : x[chartData.nestedData!.value]
                }
            }, {})
            const data = nestedTraces.map((val: any) => dataObj[val] !== undefined ? dataObj[val] : 0)
            return [item.clientName, ...data];
        })
        const barChartData: any = [axis, ...logsData];
        setChartData({...chartData, dataSet: barChartData});

        const fileData = filteredLogs.reduce((prev, client) => {
            const tracesData = client[chartData.dataField] && client[chartData.dataField].map(x => {
                return {
                    properties: [
                        {
                            propertyKey: 'client',
                            propertyValue: client.clientName
                        },
                        {
                            propertyKey: capitalize(chartData.nestedData!.name),
                            propertyValue: x[chartData.nestedData!.name]
                        },
                        {
                            propertyKey: capitalize(chartData.nestedData!.value),
                            propertyValue: `${x[chartData.nestedData!.value].toString()}`
                        },
                    ]
                }
            })
            if (Array.isArray(tracesData)) {
                return [...prev, ...tracesData];
            } else {
                return [];
            }
        }, [])
        setCsvData(fileData);
    }

    const generateChartDataFromLocationLogs = () => {
        const axis = ['Country', 'Users count'];
        const countryList = filteredLogs.reduce((acc: any, x: any) => {
            const countryNames = x.countries && x.countries.map(location => location.country).filter(name => name !== '');
            if (Array.isArray(countryNames)) {
                return [...acc, ...countryNames]
            } else {
                return [];
            }
        }, [])
        const uniqueCountries = Array.from(new Set(countryList)).sort();
        const allLocationsData = filteredLogs.reduce((prev: any, x: any) => {
            if (Array.isArray(x.countries)) {
                return [...prev, ...x.countries];
            } else {
                return [];
            }
        }, [])
        const data = uniqueCountries.map((item => {
            let count = 0;
            allLocationsData.forEach(x => {
                if (item === x.country) {
                    count = count + x.usersCount
                }
            })
            return [item, count];
        }))
        const locationsData: any = [axis, ...data];
        setChartData({...chartData, dataSet: locationsData});
    }

    const generateChartDataForEachClientFromLocationLogs = () => {
        const countriesByClient = filteredLogs.map(x => {
            const axis = [x.clientName, 'Users count'];
            const data = x.countries && x.countries.map(item => {
                return [item.country, item.usersCount];
            })
            if (Array.isArray(data)) {
                return [axis, ...data];
            } else {
                return [axis];
            }
        }, [])
        setChartData({...chartData, dataSet: countriesByClient});
    }

    const generateLineChartDataFromSimpleLogs = () => {
        const axis = ['', ...columns];
        const data = uniqueDates.map(date => {
            const countByClient = filteredLogs.map((x) => {
                const dateInfo = x[chartData!.lineChartDateType].find(y => y.date === date)
                return dateInfo ? dateInfo.count : 0
            }, [])
            const dateFormatted = formatDate(date);
            return [dateFormatted, ...countByClient]
        })
        const lineChartData: any = columns.length > 0 ? [axis, ...data] : [['', '']];
        setChartData({...chartData, dataSet: lineChartData});

        const fileData = filteredLogs.reduce((prev, client) => {
            const dataByDates = client[chartData!.lineChartDateType].map(x => {
                return {
                    properties: [
                        {
                            propertyKey: 'client',
                            propertyValue: client.clientName
                        },
                        {
                            propertyKey: capitalize(chartData.dataField),
                            propertyValue: x.count.toString()
                        },
                        {
                            propertyKey: 'Date',
                            propertyValue: formatDateForCsv(x.date)
                        },
                    ]
                }
            })
            return [...prev, ...dataByDates];
        }, [])
        setCsvData(fileData);
    }

    const generateLineChartDataFromMultiLogs = () => {
        const axis = ['Date', ...chartData.traces];
        const data = uniqueDates.map(date => {
            const multiClientData = filteredLogs.map(client => {
                return chartData.dataField.map(field => {
                    const dateNeeded = client[chartData!.lineChartDateType].find(item => date === item.date);
                    return dateNeeded && dateNeeded[field];
                })
            })
            const dateFormatted = formatDate(date);
            const summaryByDate: number[] = chartData.dataField.map(_ => 0);

            multiClientData.forEach(client => {
                client.forEach((val, i) => {
                    summaryByDate[i] += val;
                })
            })
            return [dateFormatted, ...summaryByDate];
        })
        const multiData = columns.length > 0 ? [axis, ...data] : [axis];
        setChartData({...chartData, dataSet: multiData});

        const fileData = filteredLogs.reduce((prev, client) => {
            const dataByDates = client[chartData!.lineChartDateType].map(x => {
                const tracesData = chartData.dataField.map(item => {
                    return {
                        propertyKey: capitalize(item),
                        propertyValue: x[item].toString()
                    }
                })
                return {
                    properties: [
                        {
                            propertyKey: 'client',
                            propertyValue: client.clientName
                        },
                        ...tracesData,
                        {
                            propertyKey: 'Date',
                            propertyValue: formatDateForCsv(x.date)
                        },
                    ]
                }
            })
            return [...prev, ...dataByDates];
        }, [])
        setCsvData(fileData);
    }

    const generateLineChartDataFromComplexLogs = () => {
        const axis = ['Date', ...nestedTraces];
        const data = uniqueDates.map(date => {
            const multiClientData = filteredLogs.map(client => {
                return nestedTraces.map(trace => {
                    const dateNeeded = client[chartData!.lineChartDateType].find(item => date === item.date);
                    const countByTrace = dateNeeded && dateNeeded[chartData.dataField].find(item => trace === item[chartData.nestedData!.name]);
                        if (countByTrace) {
                            return countByTrace[chartData.nestedData!.value];
                        } else {
                            return 0;
                        }
                    })
                })
            const dateFormatted = formatDate(date);
            const summaryByDate: number[] = nestedTraces.map(_ => 0);

            multiClientData.forEach(client => {
                client.forEach((val, i) => {
                    summaryByDate[i] += val;
                })
            })
            return [dateFormatted, ...summaryByDate];
        })
        const complexData = columns.length > 0 ? [axis, ...data] : [axis];
        setChartData({...chartData, dataSet: complexData});

        const fileData = filteredLogs.reduce((prev, client) => {
            const dataByDates = client[chartData!.lineChartDateType].reduce((acc, x) => {
                const tracesData = x[chartData.dataField].map(item => {
                    return {
                        properties: [
                            {
                                propertyKey: 'client',
                                propertyValue: client.clientName
                            },
                            {
                                propertyKey: capitalize(chartData.nestedData!.name),
                                propertyValue: item[chartData.nestedData!.name]
                            },
                            {
                                propertyKey: capitalize(chartData.nestedData!.value),
                                propertyValue: item[chartData.nestedData!.value].toString()
                            },
                            {
                                propertyKey: 'Date',
                                propertyValue: formatDateForCsv(x.date)
                            },
                        ]
                    }
                })
                return [...acc, ...tracesData];
            }, [])
            return [...prev, ...dataByDates];
        }, [])
        setCsvData(fileData);
    }

    const onClickGenerateLineChart = (dateType) => {
        setChartData({ ...chartData, lineChartDateType: dateType, type: ChartType.LineChart});
    }

    const onClickGenerateBarChart = () => {
        setChartData({ ...chartData, type: ChartType.BarChart});
    }

    const setLogsChartData = (data) => {
        setChartData(data);
    }

    const changeFocusedInput = (focusedInput) => {
        setFocusedInput(focusedInput);
    }

    const setActualColumns = (data: any) => {
        const filteredData = logs.filter((client: any ) => data.includes(client.clientName));
        setColumns(data);
        setFilteredLogs(filteredData);
    }

    const datesChangeHandler = (startDate: Moment | null, endDate: Moment | null) => {
        if (endDate === null) {
            endDate = startDate!.clone();
        }
        if (startDate === null) {
            return
        }

        const columnNames = logs.map((client: any) => client.clientName);
        const possibleColumns = columnNames.map((x: string) => ({ key: x, text: x, value: x }));

        checkDateBeforeSet(startDate, setStartDate);
        checkDateBeforeSet(endDate, setEndDate);

        setColumns(columnNames);
        setPossibleColumns(possibleColumns);
    }

    const formatDate = (day) => {
        if (chartData.lineChartDateType === DateType.weeks) {
            return `${moment(day).format('DD/MMM/YY')} → \n ${moment(day).add(1, 'w').format('DD/MMM/YY')}`
        } else {
            return new Date(day);
        }
    }

    const formatDateForCsv = (day) => {
        if (chartData.lineChartDateType === DateType.weeks) {
            return `${moment(day).format('DD/MMM/YY')} >> \n ${moment(day).add(1, 'w').format('DD/MMM/YY')}`
        } else if (chartData.lineChartDateType === DateType.days) {
            return moment(day).format('DD/MMM/YYYY');
        } else {
            return moment(day).format('DD/MMM/YYYY, HH:mm');
        }
    }

    const showSystemOverloadPopup = (value) => {
        setSystemOverloadPopupVisible(value)
    }

    const downloadCsv = () => {
        const name = Paths[chartData.caption];
        downloadDetailsCsv(csvData, name, startDate.format("YYYY-MM-DDTHH:mm:ss"), endDate.format("YYYY-MM-DDTHH:mm:ss"));
    }

    const downloadImages = async () => {
        toastr.success("Please wait", "PDF download can take several seconds");
        const imagesPromise = await chartImages.map(async id => {
            const el = document.getElementById(id);
            const canvas = await html2canvas(el!);
            return canvas.toDataURL();
        })

        Promise.all(imagesPromise).then(values => {
            savePDF(values);
            setChartImages([]);
        })
    }

    return <div className="charts-page">
        <SideStatsControls
            setChartData={setLogsChartData}
            plotData={chartData}
            checkCaptionForRequest={checkCaptionForRequest}
        />
        <div className="charts-page__controls-wrapper">
            <SystemOverloadPopup
                systemOverloadPopupVisible={systemOverloadPopupVisible}
                showSystemOverloadPopup={showSystemOverloadPopup}
            />
            <h2>{chartData.caption}</h2>
            <div className="charts-page__controls">
                <div>
                    <ClientsDropdown
                        possibleOptions={possibleColumns}
                        values={columns}
                        onChange={setActualColumns}
                    />
                    {
                        chartData.isLineChartsRequired &&
                        <div className="charts-page__buttons">
                            <Button onClick={onClickGenerateBarChart}
                                    color={chartData.type === ChartType.BarChart ? 'olive' : 'grey'}>Bar Chart
                            </Button>
                            <Button onClick={() => onClickGenerateLineChart(DateType.hours)}
                                    color={chartData.type === ChartType.LineChart && chartData.lineChartDateType === DateType.hours ? 'olive' : 'grey'}
                                    disabled={bigScopeOfData}>Hours
                            </Button>
                            <Button onClick={() => onClickGenerateLineChart(DateType.days)}
                                    color={chartData.type === ChartType.LineChart && chartData.lineChartDateType === DateType.days ? 'olive' : 'grey'}>Days
                            </Button>
                            <Button onClick={() => onClickGenerateLineChart(DateType.weeks)}
                                    color={chartData.type === ChartType.LineChart && chartData.lineChartDateType === DateType.weeks ? 'olive' : 'grey'}>Weeks
                            </Button>
                        </div>
                    }
                </div>
                <div>
                    <DateRangePicker
                        displayFormat={() => "DD/MM/YYYY"}
                        startDate={startDate}
                        endDate={endDate}
                        onDatesChange={({ startDate, endDate }) => {
                            datesChangeHandler(startDate, endDate);
                        }}
                        startDateId={moment().toString()}
                        endDateId={moment().add('7', 'd').toString()}
                        focusedInput={focusedInput}
                        onFocusChange={focusedInput => changeFocusedInput(focusedInput)}
                        isOutsideRange={day => isInclusivelyAfterDay(day + 1, moment())}
                        minimumNights={0}
                    />
                    {   chartData.type === ChartType.PieChart &&
                        <Button onClick={downloadImages}>Download as PDF</Button>
                    }
                </div>
            </div>
            <StatisticsCharts
                startDate={startDate}
                endDate={endDate}
                chartData={chartData}
                chartImages={chartImages}
            />
        </div>
    </div>
}

export default NewStatisticsChartsPage;
