import { call, put, all, takeLatest } from "redux-saga/effects";
import {
    getContractSpecData,
    getStatisticsTableData,
    getStatisticsTableDataForGroup,
    getUsersData,
    getUsersDataForGroup,
    getSessionsData,
    getSessionsDataForGroup,
    getSessionsByDeviceData,
    getSessionsByDeviceDataForGroup,
    getTotalDurationData,
    getTotalDurationDataForGroup,
    getStreamFormatData,
    getStreamFormatDataForGroup,
    getStreamFormatDurationData,
    getStreamFormatDurationDataForGroup,
    getSportsData,
    getSportsDataForGroup,
    getAverageScreensData,
    getAverageScreensDataForGroup,
    getDownloadsData,
    getDownloadsDataForGroup,
    getEventsData,
    getEventsDataForGroup,
    getLocationsDataForGroup,
    getLocationsData,
    getContentProvidersData, getContentProvidersDataForGroup, getContractSpecDataForGroup
} from '../../services/statistics.service';
import {
    getContractSpecDataRoutine,
    getStatisticsTableDataRoutine,
    getStatisticsTableDataForGroupRoutine,
    getUsersDataRoutine,
    getUsersDataForGroupRoutine,
    getSessionsDataRoutine,
    getSessionsDataForGroupRoutine,
    getSessionsByDeviceDataRoutine,
    getSessionsByDeviceDataForGroupRoutine,
    getTotalDurationDataRoutine,
    getTotalDurationDataForGroupRoutine,
    getStreamFormatDataRoutine,
    getStreamFormatDataForGroupRoutine,
    getStreamFormatDurationDataRoutine,
    getStreamFormatDurationDataForGroupRoutine,
    getSportsDataRoutine,
    getSportsDataForGroupRoutine,
    getAverageScreensDataRoutine,
    getAverageScreensDataForGroupRoutine,
    getDownloadsDataRoutine,
    getDownloadsDataForGroupRoutine,
    getEventsDataRoutine,
    getEventsDataForGroupRoutine,
    getLocationsDataForGroupRoutine,
    getLocationsDataRoutine,
    getContentProvidersDataRoutine,
    getContentProvidersDataForGroupRoutine, getContractSpecDataForGroupRoutine
} from '../routines';

function* getContractSpec(action: any) {
    try {
        const { startDate, endDate } = action.payload;
        const response = yield call(getContractSpecData, startDate, endDate);
        yield put(getContractSpecDataRoutine.success(response.data));

    } catch (err) {
        yield put(getContractSpecDataRoutine.failure(err.message));
    }
}

function* getContractSpecForGroup(action: any) {
    try {
        const { groupId, startDate, endDate } = action.payload;
        const response = yield call(getContractSpecDataForGroup, groupId, startDate, endDate);
        yield put(getContractSpecDataForGroupRoutine.success(response.data));

    } catch (err) {
        yield put(getContractSpecDataForGroupRoutine.failure(err.message));
    }
}

function* getStatisticsTable(action: any) {
    try {
        const { startDate, endDate } = action.payload;
        const response = yield call(getStatisticsTableData, startDate, endDate);
        yield put(getStatisticsTableDataRoutine.success(response.data));

    } catch (err) {
        yield put(getStatisticsTableDataRoutine.failure(err.message));
    }
}

function* getStatisticsTableForGroup(action: any) {
    try {
        const { groupId, startDate, endDate } = action.payload;
        const response = yield call(getStatisticsTableDataForGroup, groupId, startDate, endDate);
        yield put(getStatisticsTableDataForGroupRoutine.success(response.data));

    } catch (err) {
        yield put(getStatisticsTableDataForGroupRoutine.failure(err.message));
    }
}

function* getUsers(action: any) {
    try {
        const { startDate, endDate } = action.payload;
        const response = yield call(getUsersData, startDate, endDate);
        yield put(getUsersDataRoutine.success(response.data));

    } catch (err) {
        yield put(getUsersDataRoutine.failure(err.message));
    }
}

function* getUsersForGroup(action: any) {
    try {
        const { groupId, startDate, endDate } = action.payload;
        const response = yield call(getUsersDataForGroup, groupId, startDate, endDate);
        yield put(getUsersDataForGroupRoutine.success(response.data));

    } catch (err) {
        yield put(getUsersDataForGroupRoutine.failure(err.message));
    }
}

function* getSessions(action: any) {
    try {
        const { startDate, endDate } = action.payload;
        const response = yield call(getSessionsData, startDate, endDate);
        yield put(getSessionsDataRoutine.success(response.data));

    } catch (err) {
        yield put(getSessionsDataRoutine.failure(err.message));
    }
}

function* getSessionsForGroup(action: any) {
    try {
        const { groupId, startDate, endDate } = action.payload;
        const response = yield call(getSessionsDataForGroup, groupId, startDate, endDate);
        yield put(getSessionsDataForGroupRoutine.success(response.data));

    } catch (err) {
        yield put(getSessionsDataForGroupRoutine.failure(err.message));
    }
}

function* getSessionsByDevice(action: any) {
    try {
        const { startDate, endDate } = action.payload;
        const response = yield call(getSessionsByDeviceData, startDate, endDate);
        yield put(getSessionsByDeviceDataRoutine.success(response.data));

    } catch (err) {
        yield put(getSessionsByDeviceDataRoutine.failure(err.message));
    }
}

function* getSessionsByDeviceForGroup(action: any) {
    try {
        const { groupId, startDate, endDate } = action.payload;
        const response = yield call(getSessionsByDeviceDataForGroup, groupId, startDate, endDate);
        yield put(getSessionsByDeviceDataForGroupRoutine.success(response.data));

    } catch (err) {
        yield put(getSessionsByDeviceDataForGroupRoutine.failure(err.message));
    }
}

function* getTotalDuration(action: any) {
    try {
        const { startDate, endDate } = action.payload;
        const response = yield call(getTotalDurationData, startDate, endDate);
        yield put(getTotalDurationDataRoutine.success(response.data));

    } catch (err) {
        yield put(getTotalDurationDataRoutine.failure(err.message));
    }
}

function* getTotalDurationForGroup(action: any) {
    try {
        const { groupId, startDate, endDate } = action.payload;
        const response = yield call(getTotalDurationDataForGroup, groupId, startDate, endDate);
        yield put(getTotalDurationDataForGroupRoutine.success(response.data));

    } catch (err) {
        yield put(getTotalDurationDataForGroupRoutine.failure(err.message));
    }
}

function* getStreamFormat(action: any) {
    try {
        const { startDate, endDate } = action.payload;
        const response = yield call(getStreamFormatData, startDate, endDate);
        yield put(getStreamFormatDataRoutine.success(response.data));

    } catch (err) {
        yield put(getStreamFormatDataRoutine.failure(err.message));
    }
}

function* getStreamFormatForGroup(action: any) {
    try {
        const { groupId, startDate, endDate } = action.payload;
        const response = yield call(getStreamFormatDataForGroup, groupId, startDate, endDate);
        yield put(getStreamFormatDataForGroupRoutine.success(response.data));

    } catch (err) {
        yield put(getStreamFormatDataForGroupRoutine.failure(err.message));
    }
}

function* getStreamFormatDuration(action: any) {
    try {
        const { startDate, endDate } = action.payload;
        const response = yield call(getStreamFormatDurationData, startDate, endDate);
        yield put(getStreamFormatDurationDataRoutine.success(response.data));

    } catch (err) {
        yield put(getStreamFormatDurationDataRoutine.failure(err.message));
    }
}

function* getStreamFormatDurationForGroup(action: any) {
    try {
        const { groupId, startDate, endDate } = action.payload;
        const response = yield call(getStreamFormatDurationDataForGroup, groupId, startDate, endDate);
        yield put(getStreamFormatDurationDataForGroupRoutine.success(response.data));

    } catch (err) {
        yield put(getStreamFormatDurationDataForGroupRoutine.failure(err.message));
    }
}

function* getSports(action: any) {
    try {
        const { startDate, endDate } = action.payload;
        const response = yield call(getSportsData, startDate, endDate);
        yield put(getSportsDataRoutine.success(response.data));

    } catch (err) {
        yield put(getSportsDataRoutine.failure(err.message));
    }
}

function* getSportsForGroup(action: any) {
    try {
        const { groupId, startDate, endDate } = action.payload;
        const response = yield call(getSportsDataForGroup, groupId, startDate, endDate);
        yield put(getSportsDataForGroupRoutine.success(response.data));

    } catch (err) {
        yield put(getSportsDataForGroupRoutine.failure(err.message));
    }
}

function* getAverageScreens(action: any) {
    try {
        const { startDate, endDate } = action.payload;
        const response = yield call(getAverageScreensData, startDate, endDate);
        yield put(getAverageScreensDataRoutine.success(response.data));

    } catch (err) {
        yield put(getAverageScreensDataRoutine.failure(err.message));
    }
}

function* getAverageScreensForGroup(action: any) {
    try {
        const { groupId, startDate, endDate } = action.payload;
        const response = yield call(getAverageScreensDataForGroup, groupId, startDate, endDate);
        yield put(getAverageScreensDataForGroupRoutine.success(response.data));

    } catch (err) {
        yield put(getAverageScreensDataForGroupRoutine.failure(err.message));
    }
}

function* getLocations(action: any) {
    try {
        const { startDate, endDate } = action.payload;
        const response = yield call(getLocationsData, startDate, endDate);
        yield put(getLocationsDataRoutine.success(response.data));

    } catch (err) {
        yield put(getLocationsDataRoutine.failure(err.message));
    }
}

function* getLocationsForGroup(action: any) {
    try {
        const { groupId, startDate, endDate } = action.payload;
        const response = yield call(getLocationsDataForGroup, groupId, startDate, endDate);
        yield put(getLocationsDataForGroupRoutine.success(response.data));

    } catch (err) {
        yield put(getLocationsDataForGroupRoutine.failure(err.message));
    }
}

function* getDownloads(action: any) {
    try {
        const { startDate, endDate } = action.payload;
        const response = yield call(getDownloadsData, startDate, endDate);
        yield put(getDownloadsDataRoutine.success(response.data));

    } catch (err) {
        yield put(getDownloadsDataRoutine.failure(err.message));
    }
}

function* getDownloadsForGroup(action: any) {
    try {
        const { groupId, startDate, endDate } = action.payload;
        const response = yield call(getDownloadsDataForGroup, groupId, startDate, endDate);
        yield put(getDownloadsDataForGroupRoutine.success(response.data));

    } catch (err) {
        yield put(getDownloadsDataForGroupRoutine.failure(err.message));
    }
}

function* getEvents(action: any) {
    try {
        const { startDate, endDate } = action.payload;
        const response = yield call(getEventsData, startDate, endDate);
        yield put(getEventsDataRoutine.success(response.data));

    } catch (err) {
        yield put(getEventsDataRoutine.failure(err.message));
    }
}

function* getEventsForGroup(action: any) {
    try {
        const { groupId, startDate, endDate } = action.payload;
        const response = yield call(getEventsDataForGroup, groupId, startDate, endDate);
        yield put(getEventsDataForGroupRoutine.success(response.data));

    } catch (err) {
        yield put(getEventsDataForGroupRoutine.failure(err.message));
    }
}

function* getContentProviders(action: any) {
    try {
        const { startDate, endDate } = action.payload;
        const response = yield call(getContentProvidersData, startDate, endDate);
        yield put(getContentProvidersDataRoutine.success(response.data));

    } catch (err) {
        yield put(getContentProvidersDataRoutine.failure(err.message));
    }
}

function* getContentProvidersForGroup(action: any) {
    try {
        const { groupId, startDate, endDate } = action.payload;
        const response = yield call(getContentProvidersDataForGroup, groupId, startDate, endDate);
        yield put(getContentProvidersDataForGroupRoutine.success(response.data));

    } catch (err) {
        yield put(getContentProvidersDataForGroupRoutine.failure(err.message));
    }
}

export default function* statisticsSaga() {
    yield all([
        takeLatest(getContractSpecDataRoutine, getContractSpec),
        takeLatest(getContractSpecDataForGroupRoutine, getContractSpecForGroup),
        takeLatest(getStatisticsTableDataRoutine, getStatisticsTable),
        takeLatest(getStatisticsTableDataForGroupRoutine, getStatisticsTableForGroup),
        takeLatest(getUsersDataRoutine, getUsers),
        takeLatest(getUsersDataForGroupRoutine, getUsersForGroup),
        takeLatest(getSessionsDataRoutine, getSessions),
        takeLatest(getSessionsDataForGroupRoutine, getSessionsForGroup),
        takeLatest(getSessionsByDeviceDataRoutine, getSessionsByDevice),
        takeLatest(getSessionsByDeviceDataForGroupRoutine, getSessionsByDeviceForGroup),
        takeLatest(getTotalDurationDataRoutine, getTotalDuration),
        takeLatest(getTotalDurationDataForGroupRoutine, getTotalDurationForGroup),
        takeLatest(getStreamFormatDataRoutine, getStreamFormat),
        takeLatest(getStreamFormatDataForGroupRoutine, getStreamFormatForGroup),
        takeLatest(getStreamFormatDurationDataRoutine, getStreamFormatDuration),
        takeLatest(getStreamFormatDurationDataForGroupRoutine, getStreamFormatDurationForGroup),
        takeLatest(getSportsDataRoutine, getSports),
        takeLatest(getSportsDataForGroupRoutine, getSportsForGroup),
        takeLatest(getAverageScreensDataRoutine, getAverageScreens),
        takeLatest(getAverageScreensDataForGroupRoutine, getAverageScreensForGroup),
        takeLatest(getLocationsDataRoutine, getLocations),
        takeLatest(getLocationsDataForGroupRoutine, getLocationsForGroup),
        takeLatest(getDownloadsDataRoutine, getDownloads),
        takeLatest(getDownloadsDataForGroupRoutine, getDownloadsForGroup),
        takeLatest(getEventsDataRoutine, getEvents),
        takeLatest(getEventsDataForGroupRoutine, getEventsForGroup),
        takeLatest(getContentProvidersDataRoutine, getContentProviders),
        takeLatest(getContentProvidersDataForGroupRoutine, getContentProvidersForGroup),
    ]);
}

