import React, {Fragment, memo, useEffect, useState} from "react";
import Chart from "react-google-charts";
import {numberOnly} from "../../../../../utils/helpers";
import moment, {Moment} from "moment";
import {Loader} from "semantic-ui-react";
import {ReactGoogleChartEvent} from "react-google-charts/dist/types";
import {ChartType} from "../../enums/chart-type";
import {useSelector} from "react-redux";
import {State} from "../../../../../common/types/state";
import {DateType} from "../../enums/date-type";
import "./styles.scss";

export interface IStatisticsChartsProps {
    startDate: Moment;
    endDate: Moment;
    chartData: any;
    chartImages: any;
}

enum Colors {
    Ribbon = '#303641',
    Background = '#F1F1F1',
    Buttons = '#98CC3E'
}

const textStyle = {
    color: Colors.Ribbon,
    fontSize: 14,
    bold: true,
};

const animation = {
    duration: 1000,
    easing: 'out',
    startup: true,
};

const chartColors = ['#475061', '#2191FB', '#BA274A', '#F374AE', '#339989', '#6884A6', '#4B1B22', '#B8B42D', '#222E2F', '#7B3D68'];

const StatisticsCharts: React.FC<IStatisticsChartsProps> = ({ startDate, endDate, chartData, chartImages}) => {
    const [dateField, setDateField] = useState('');
    const loading = useSelector((state: State) => state.statistics.detailsLogs.isLoading);

    useEffect(() => {
        setDateField(chartData.lineChartDateType);
    }, [chartData.lineChartDateType]);

    const formatDate = (dateSymbol: string) => {
        return `dd-MMM-yyyy${dateSymbol === 'hoursInfo' ? ', ha' : ''}`;
    }

    const renderChart = () => {
        const chartEvents: [ReactGoogleChartEvent] = [{
            eventName: 'ready',
            callback: ({chartWrapper}) => {
                const id = chartWrapper.getContainerId();
                if (!chartImages.includes(id)) {
                    chartImages.push(id);
                }
            }
        }]

        const maxNumberForVerticalScale = Math.max.apply(null, (chartData!.dataSet
            .map(x => Array.isArray(x) ? x.map(numberOnly) : numberOnly(x)) as number[][])
            .map((row) => Array.isArray(row) ? Math.max.apply(Math, row) : row))

        const containNumericData = chartData.dataSet[1] && chartData.dataSet[1]!.some(x => typeof x === 'number')
            || (chartData.dataSet[0] !== null
            && chartData.dataSet[0] !== undefined
            && Object.values(chartData.dataSet[0]).some((y: any) => Array.isArray(y) && y.some(z => typeof z === 'number')));

        if (loading) {
            return <Loader active content='Loading chart...'/>
        } if (chartData.type !== ChartType.PieChart && chartData.dataSet.length === 1
            || !containNumericData
            || chartData.type === ChartType.PieChart && chartData.dataSet.length === 0) {
            return <div style={{fontSize: '20px'}}>No data for selected period</div>
        } else {
            if (chartData.type === ChartType.LineChart && chartData.lineChartDateType !== DateType.weeks) {
                return <Chart
                    width={'100%'}
                    height={280}
                    chartType={chartData!.type}
                    loader={<div>Chart Rendering...</div>}
                    data={chartData!.dataSet}
                    options={{
                        backgroundColor: Colors.Background,
                        animation: animation,
                        colors: chartColors,
                        chartArea: { height: '70%', width: '70%' },
                        hAxis: {
                            format: formatDate(dateField),
                            slantedText: false,
                            gridlines: { minSpacing: 1 },
                            textStyle: { ...textStyle },
                            titleTextStyle: { ...textStyle, fontSize: 18 },
                            baseLineColor: Colors.Ribbon,
                            gridLineColor: Colors.Ribbon,
                        },
                        vAxis: {
                            viewWindow: {
                                min: 0,
                                max: maxNumberForVerticalScale
                            },
                            title: chartData.scaleSubtitle,
                            textStyle: { ...textStyle },
                            titleTextStyle: { ...textStyle, fontSize: 18 },
                            baseLineColor: Colors.Ribbon,
                            gridLineColor: Colors.Ribbon,
                            gridlines: { minSpacing: 50 }
                        },
                    }}
                    chartPackages={['corechart', 'controls']}
                    controls={[{
                        controlType: 'ChartRangeFilter',
                        options: {
                            filterColumnIndex: 0,
                            curveType: "function",
                            ui: {
                                chartType: 'LineChart',
                                chartOptions: {
                                    backgroundColor: Colors.Background,
                                    colors: chartColors,
                                    chartArea: { width: '70%', height: '40%' },
                                    hAxis: {
                                        format: 'dd-MMM-yyyy',
                                        baselineColor: 'none',
                                        textStyle: { ...textStyle },
                                        titleTextStyle: { ...textStyle, fontSize: 14 },
                                        baseLineColor: Colors.Ribbon,
                                        gridLineColor: Colors.Ribbon
                                    },
                                    vAxis: {
                                        textStyle: { ...textStyle },
                                        titleTextStyle: { ...textStyle, fontSize: 18 },
                                        baseLineColor: Colors.Ribbon,
                                        gridLineColor: Colors.Ribbon
                                    }
                                },
                            },
                        },
                        controlPosition: 'bottom',
                        controlWrapperParams: {
                            state: {
                                range: { start: moment(startDate as Moment).toDate(), end: moment(endDate as Moment).toDate() },
                            },
                        },
                    }
                    ]}
                />
            } else if (chartData.type === ChartType.PieChart && chartData.multiChart) {
                return <div className="multi-chart-container">
                    {
                        chartData.dataSet.map((clientDataSet, i) => {
                            return <Chart
                                key={i + 10}
                                width={730}
                                height={450}
                                chartType={chartData.type}
                                loader={<div>Chart Rendering...</div>}
                                data={clientDataSet}
                                chartEvents={chartEvents}
                                options={{
                                    legend: {
                                        position: 'right'
                                    },
                                    animation: animation,
                                    backgroundColor: Colors.Background,
                                    colors: chartColors,
                                    title: clientDataSet[0][0],
                                    chartArea: { height: '85%', width: '85%' },
                                    hAxis: {
                                        minValue: 0,
                                        slantedText: false,
                                        textStyle: { ...textStyle },
                                        titleTextStyle: { ...textStyle, fontSize: 18 },
                                        baseLineColor: Colors.Ribbon,
                                        gridLineColor: Colors.Ribbon
                                    },
                                    isStacked: clientDataSet.isStacked,
                                    vAxis: {
                                        textStyle: { ...textStyle },
                                        titleTextStyle: { ...textStyle, fontSize: 18 },
                                        baseLineColor: Colors.Ribbon,
                                        gridLineColor: Colors.Ribbon
                                    }
                                }}
                                chartPackages={['corechart', 'controls']}
                            />
                        })
                    }
                </div>
            } else {
                return <Chart
                    key={1}
                    width={'100%'}
                    height={450}
                    chartType={chartData!.type}
                    loader={<div>Chart Rendering...</div>}
                    data={chartData!.dataSet}
                    options={{
                        backgroundColor: Colors.Background,
                        colors: chartColors,
                        animation: animation,
                        chartArea: { height: '60%', width: '70%' },
                        hAxis: {
                            title: chartData.type === ChartType.LineChart ? 'Time' : chartData.scaleSubtitle,
                            minValue: 0,
                            slantedText: chartData.type === ChartType.LineChart,
                            textStyle: { ...textStyle },
                            titleTextStyle: { ...textStyle, fontSize: 18 },
                            baseLineColor: Colors.Ribbon,
                            gridLineColor: Colors.Ribbon,
                        },
                        isStacked: chartData.isStacked,
                        vAxis: {
                            title: chartData.type === ChartType.LineChart ? chartData.scaleSubtitle : 'Client',
                            textStyle: { ...textStyle },
                            titleTextStyle: { ...textStyle, fontSize: 18 },
                            baseLineColor: Colors.Ribbon,
                            gridLineColor: Colors.Ribbon,
                            gridlines: { minSpacing: 50 }
                        },
                    }}
                    chartPackages={['corechart', 'controls']}
                />
            }
        }
    }

    return (
        <Fragment>
            <div className="chart-container">
                {
                    renderChart()
                }
            </div>
        </Fragment>
    )
}

export default memo(StatisticsCharts);


