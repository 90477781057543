import React from "react";
import UsersTable from "../../user-management/users-table/users-table";

const GroupInfoPage = (props) => {
    const id = props.match.params['groupId'];
    return (
        <div className="table-container">
            <UsersTable id={id} isGroupViewPage={false} />
        </div>
    )
}

export default GroupInfoPage;