import { call, put, all, takeLatest } from "redux-saga/effects";
import { fetchCompetitions } from '../routines/index';
import { getCompetitions } from '../../services/schedules.service';

function* getCompetition() {
    try {
        const response = yield call(getCompetitions);

        yield put(fetchCompetitions.success(response.data));
    } catch (err) {
        yield put(fetchCompetitions.failure(err.message));
    }
}

export default function* eventsSaga() {
    yield all([
        takeLatest(fetchCompetitions, getCompetition)
    ]);
}