import React, {useEffect, useState} from 'react'
import { Modal, Form, Button } from 'semantic-ui-react';
import { createSsoDomainRoutine, updateSsoDomainRoutine } from '../../../../redux/routines';
import {SsoDomain} from "../../../../common/types/sso";
import {useDispatch} from "react-redux";

import './sso-modal.scss';
import {toastr} from "react-redux-toastr";

export interface StreamSettingsModalProps {
    modalOpened: boolean;
    closeModal: () => void;
    isNew: boolean;
    existedSsoDomain?: SsoDomain;
    refreshForm: () => void;
    ssoDomains: SsoDomain[];
}

const StreamSettingsModal: React.FC<StreamSettingsModalProps> = ({
         modalOpened,
         closeModal,
         isNew,
         existedSsoDomain,
         refreshForm,
         ssoDomains
}) => {
    const initialValue: string = isNew ? "" : existedSsoDomain ? existedSsoDomain!.domain : "";
    const [domain, setDomain] = useState(initialValue);
    const dispatch = useDispatch();

    useEffect(() => {
        setDomain(initialValue);
    }, [existedSsoDomain]);

    const handleSubmit = () => {
        const regex = /^([a-zA-Z0-9]([a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}$/
        const domainAlreadyExist = ssoDomains.some(x => x.domain.toLowerCase() === domain.toLowerCase());

        if (!regex.test(domain)) {
            toastr.error("Error!", "Invalid domain format");
            return;
        }
        if (domainAlreadyExist && existedSsoDomain?.domain.toLowerCase() !== domain.toLowerCase()) {
            toastr.error("Error!", "Domain with the same name already exist");
            return;
        }

        if(isNew) {
            dispatch(createSsoDomainRoutine({domain}));
        } else {
            dispatch(updateSsoDomainRoutine({
                domain,
                id: existedSsoDomain?.id
            }));
        }
        refreshForm();
        setDomain("");
        closeModal();
    }

    return (
        <Modal
            open={modalOpened}
            onClose={closeModal}>
            <Modal.Header>
                Input new domain name
            </Modal.Header>
            <Modal.Content>
                <Form.Input
                    label="Domain name"
                    fluid
                    onChange={(_, e) => setDomain(e.value)}
                    type="text"
                    name="text"
                    placeholder="Enter domain"
                    value={domain}
                />
            </Modal.Content>
            <div className="sso-btn-container">
                <Button className="sso-btn-submit" color="green" onClick={handleSubmit}>
                    Submit
                </Button>
                <Button className="ui basic button sso-btn-cancel" onClick={closeModal}>
                    Cancel
                </Button>
            </div>
        </Modal>
    )
}

export default StreamSettingsModal;
