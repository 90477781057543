import React, { useEffect } from 'react';
import UsersTable from '../users-table/users-table';
import './styles.scss'
import { User } from '../../../../common/types/user';
import { connect } from 'react-redux';
import env from '../../../../env';
import { State } from '../../../../common/types/state';

export interface IUsersPageProps {
    user: User;
}
const UsersPage: React.FC<IUsersPageProps> = ({ user }) => {

    useEffect(() => {
        if (!user)
            window.location.href = `${env.inplayUrl}/logout`
    });

    return (
        <div className="table-container">
            <h2>Users Management Page</h2>
            <UsersTable isGroupViewPage={false} />
        </div>
    )
}

const mapStateToProps = (state: State) => {
    return {
        user: state.user
    }
}


export default connect(mapStateToProps)(UsersPage);