import {Routine} from "redux-saga-routines";
import {
    createAnnouncementRoutine,
    deleteAnnouncementRoutine,
    getAnnouncementsRoutine,
    updateAnnouncementRoutine
} from "../../routines";

export const announcementsReducer = (state: any = { source: [], isLoading: false }, action: Routine<any>) => {
    switch (action.type) {
        case getAnnouncementsRoutine.TRIGGER:
            return {
                ...state,
                isLoading: true
            }

        case getAnnouncementsRoutine.SUCCESS:
            return {
                source: action.payload,
                isLoading: false
            }

        case createAnnouncementRoutine.SUCCESS:
            return {
                source: [...state.source, {id: '...', announcement: action.payload.announcement}],
            }

        case updateAnnouncementRoutine.SUCCESS:
            const index = state.source.findIndex(x => x.id === action.payload.id);

            return {
                source: [
                    ...state.source.slice(0, index),
                    {
                        ...action.payload.model,
                        id: action.payload.id
                    },
                    ...state.source.slice(index + 1)
                ]
            }

        case deleteAnnouncementRoutine.SUCCESS:
            return {
                source: state.source.filter(x => x.id !== action.payload.id),
            }

        default:
            return state;
    }
}