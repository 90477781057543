import { all, call, put, takeLatest } from "redux-saga/effects";
import {
    fetchDevicesRoutine, fetchGroup,
    removeAllDevicesRoutine,
    removeDeviceRoutine,
    removeGroupDevicesRoutine
} from "../routines";
import {
    getUserDevices,
    removeAllUserDevices,
    removeGroupDevicesRequest,
    removeUserDevice
} from "../../services/device.service";
import {toastr} from "react-redux-toastr";

function* getDevices(action: any) {
    try {
        const response = yield call(getUserDevices, action.payload);
        yield put(fetchDevicesRoutine.success(response.data));
    } catch (err) {
        yield put(fetchDevicesRoutine.failure(err));
    }
}

function* removeDevice(action: any) {
    try {
        yield call(removeUserDevice, action.payload);
        toastr.success("Success", "Device association removed!");
        yield put(removeDeviceRoutine.success(action.payload));
    } catch (err) {
        yield put(removeDeviceRoutine.failure(err));
    }
}

function* removeAllDevices(action: any) {
    try {
        yield call(removeAllUserDevices, action.payload);
        toastr.success("Success", "All user's devices have been removed!");
        yield put(removeAllDevicesRoutine.success(action.payload));
    } catch (err) {
        yield put(removeAllDevicesRoutine.failure(err));
    }
}

function* removeGroupDevices(action: any) {
    try {
        yield call(removeGroupDevicesRequest, action.payload);
        toastr.success("Success", "All users' devices have been removed!");
        yield put(removeGroupDevicesRoutine.success(action.payload));
    } catch (err) {
        yield put(removeGroupDevicesRoutine.failure(err));
    }
}

export default function* deviceSaga() {
    yield all([
        takeLatest(fetchDevicesRoutine, getDevices),
        takeLatest(removeDeviceRoutine, removeDevice),
        takeLatest(removeAllDevicesRoutine, removeAllDevices),
        takeLatest(removeGroupDevicesRoutine, removeGroupDevices)
    ]);
}

