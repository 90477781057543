export enum Paths {
    'Users' = 'users',
    'Sessions' = 'sessions',
    'Devices' = 'devices',
    'Total Duration' = 'duration',
    'Stream Format' = 'stream-format',
    'Duration By Stream Format' = 'stream-format-duration',
    'Sports' = 'sports',
    'Average Screens' = 'average-screens',
    'Locations' = 'locations',
    'Downloads' = 'downloads',
    'Distinct Events' = 'events',
    'Content Providers' = 'content-providers',
}