import React, { Fragment, useState } from 'react'
import { Transition } from 'react-transition-group'
import { Icon } from 'semantic-ui-react'
import { ISidebarContentProps } from '../../interfaces/sidebar-item'
import './styles.scss'

const duration = 500

const sidebarStyle = {
    transition: `width ${duration}ms`
}
const sidebarTransitionStyles = {
    entering: { width: 0 },
    entered: { width: '250px' },
    exiting: { width: '250px' },
    exited: { width: 0 }
}

const linkStyle = {
    transition: `opacity ${duration}ms`
}

const linkTransitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 1 },
    exited: { opacity: 0 }
}

const selectedStyles = {
    borderRight: '5px solid dimgray',
    backgroundColor: 'gainsboro'
}

export const SidebarContent: React.FC<ISidebarContentProps> = ({ isOpen, items }) => {
    const [selectedItem, setSelectedItem] = useState('Users');
    const [displayChildItems, setDisplayChildItems] = useState(false);

    const renderLinks = () => {
        return (
            <Transition in={isOpen} timeout={duration}>
                {(state) => (
                    <div style={{
                        ...linkStyle,
                        ...linkTransitionStyles[state]
                    }}>
                        {
                            isOpen && items.map(x => {

                                return !x.childen ? <div
                                    className='sidebar-link'
                                    onClick={() => {
                                        setSelectedItem(x.name);
                                        x.onClick();
                                    }}
                                    style={selectedItem === x.name ? selectedStyles : undefined}>
                                    <Icon name={x.iconName as any} size='large' />
                                    {x.name}
                                </div> :
                                    (<Fragment>
                                        <div
                                            className='sidebar-link'
                                            onClick={() => {
                                                setSelectedItem(x.name);
                                                setDisplayChildItems(!displayChildItems)
                                            }}
                                            style={selectedItem === x.name ? selectedStyles : undefined}>
                                            <Icon name={x.iconName as any} size='large' />
                                            {x.name}
                                        </div>
                                        {
                                            displayChildItems &&
                                            x.childen.map(x => {
                                                return <div
                                                    className='sidebar-link'
                                                    onClick={() => {
                                                        setSelectedItem(x.name);
                                                        x.onClick()
                                                    }}
                                                    style={selectedItem === x.name ? { ...selectedStyles, marginLeft: 15 } : { marginLeft: 15 }}>
                                                    <Icon name={x.iconName as any} size='large' />
                                                    {x.name}
                                                </div>
                                            })
                                        }
                                    </Fragment>
                                    )
                            })
                        }
                    </div>
                )}
            </Transition>
        )
    }

    return (
        <Transition in={isOpen} timeout={duration}>
            {(state) => (
                <div className="sidebar" style={{
                    ...sidebarStyle,
                    ...sidebarTransitionStyles[state]
                }}>
                    {renderLinks()}
                </div>
            )}
        </Transition>
    )
}