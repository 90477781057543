import {
    fetchGroup,
    fetchGroups,
    createGroup,
    putGroups,
    deleteGroup,
    removeOrRestoreUserByEmailRoutine,
    removeUserRoutine,
    addUserToGroup,
    updateUserInGroup, restoreGroupRoutine,
} from "../../routines";

export const groupsReducer = (state: any = { source: [], isLoading: true, exception: null }, action: any) => {
    switch (action.type) {
        case fetchGroup.TRIGGER:
        case fetchGroups.TRIGGER:
            return {
                source: state.source,
                isLoading: true
            };
        case fetchGroup.SUCCESS:
            return {
                source: action.payload,
                isLoading: false
            };
        case fetchGroups.SUCCESS:
            return {
                source: action.payload,
                isLoading: false
            };
        case createGroup.TRIGGER: {
            return {
                source: state.source,
                isLoading: true
            }
        }
        case createGroup.SUCCESS: {
            return {
                source: [...state.source, action.payload],
                isLoading: false
            };
        }
        case createGroup.FAILURE: {
            return {
                source: state.source,
                isLoading: false,
                exception: "error"
            }
        }
        case restoreGroupRoutine.SUCCESS: {
            const index = state!.source.findIndex(group => group.id === action.payload);
            const itemToRestore = { ...state!.source[index] }

            return {
                source: [...state!.source.slice(0, index), {...itemToRestore, isDeleted: false}, ...state!.source.slice(index + 1, state!.source.length)],
                isLoading: false
            }
        }
        case removeOrRestoreUserByEmailRoutine.SUCCESS: {
            const index = state!.source.users.findIndex(u => u.id === action.payload.id);
            const item = { ...state!.source.users[index] }
            item.isDeleted = action.payload.isDeleted;
            return {
                source: {
                    ...state.source,
                    users: [...state!.source.users.slice(0, index), item, ...state!.source.users.slice(index + 1, state!.source.users.length)]
                },
                isLoading: false
            }
        }
        case removeUserRoutine.SUCCESS: {
            return {
                source: {
                    ...state.source,
                    users: state!.source.users.filter(x => x.id !== action.payload)
                },
                isLoading: false
            }
        }
        case addUserToGroup.SUCCESS: {
            return {
                source: {
                    ...state.source,
                    users: !action.payload.error
                        ?
                        [
                            ...state.source.users,
                            action.payload.user
                        ]
                        : state.source.users
                },
                isLoading: false,
                exception: action.payload.error
            }
        }
        case updateUserInGroup.SUCCESS: {
            const index = state.source.users.findIndex(u => u.id === action.payload.id);

            return {
                source: {
                    ...state.source,
                    users: [
                        ...state.source.users.slice(0, index),
                        action.payload,
                        ...state.source.users.slice(index + 1)
                    ]
                },
                isLoading: false
            }
        }
        case deleteGroup.SUCCESS:
            if (action.payload.hardDelete) {
                return {
                    source: [...state.source.filter(x => x.id !== action.payload.groupId)],
                    isLoading: false
                };
            } else {
                const index = state!.source.findIndex(group => group.id === action.payload.groupId);
                const softDeletedGroup = { ...state!.source[index] }

                return {
                    source: [...state!.source.slice(0, index), {...softDeletedGroup, isDeleted: true}, ...state!.source.slice(index + 1, state!.source.length)],
                    isLoading: false
                }
            }

        case putGroups.TRIGGER: {
            return {
                source: state.source,
                isLoading: true
            }
        }
        case putGroups.SUCCESS:
            const index = state.source.findIndex(u => u.id === action.payload.id);
            return {
                source: [
                    ...state.source.slice(0, index),
                    action.payload,
                    ...state.source.slice(index + 1, state!.source.length)
                ],
                isLoading: false
            };
        case putGroups.FAILURE: {
            return {
                source: state.source,
                isLoading: false,
                exception: "error"
            }
        }

        default:
            return state
    }
}
