import { Routine } from "redux-saga-routines";
import { EventLog } from "../../../common/types/eventLog";
import { getDetailedEventsForTiersRoutine } from "../../routines";

export const detailedEventsReducer = (state: EventLog[] = [], action: Routine<any>) => {
    switch (action.type) {
        case getDetailedEventsForTiersRoutine.SUCCESS:
            return action.payload;
        default:
            return state;
    }
}