import React, { useEffect, useState } from 'react';
import { Table, Loader, Pagination, PaginationProps, Icon, Button, Search } from 'semantic-ui-react';
import { State } from '../../../../common/types/state';
import SsoModal from '../sso-modal/sso-modal';
import {useDispatch, useSelector} from "react-redux";
import {SsoDomain} from "../../../../common/types/sso";
import {deleteSsoDomainRoutine} from "../../../../redux/routines";

import './sso-table.scss';
import {ConfirmModal} from "../../../../common/components/confirm-model";

export interface ISsoTableProps {}

const SsoTable: React.FC<ISsoTableProps> = () => {
    const [activePage, setActivePage] = useState(1);
    const [begin, setBegin] = useState(0);
    const [end, setEnd] = useState(20);
    const [displayedSsoDomains, setDisplayedSsoDomains] = useState([] as any);
    const [modalOpened, setModalOpened] = useState(false);
    const [isNewModal, setIsNewModal] = useState(false);
    const [existedSsoDomain, setExistedSsoDomain] = useState(undefined as any)
    const [loading, setLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [searchCriteria, setSearchCriteria] = useState('');
    const [searchedSsoDomains, setSearchedSsoDomains] = useState([] as any);
    const [removeSsoModalOpen, setRemoveSsoModalOpen] = useState(false);
    const [idToBeDeleted, setIdToBeDeleted] = useState(null);

    const ssoDomains: SsoDomain[] = useSelector((state: State) => state.ssoDomains.source);
    const isLoading = useSelector((state: State) => state.ssoDomains.isLoading);

    const dispatch = useDispatch();

    useEffect(() => {
        setDisplayedSsoDomains(ssoDomains && ssoDomains.length ? ssoDomains.slice(begin, end) : []);
    }, [ssoDomains])

    useEffect(() => {
        if (ssoDomains) {
            setDisplayedSsoDomains(ssoDomains.slice(begin, end));
            if (searchedSsoDomains.length) {
                setDisplayedSsoDomains(searchedSsoDomains.slice(begin, end));
            }
        }
        setLoading(false)
    }, [loading])

    const moveNextPage = (event: React.MouseEvent<HTMLAnchorElement>, data: PaginationProps) => {
        setActivePage(data.activePage as number);
        setBegin(data.activePage as number * 20 - 20);
        setEnd(data.activePage as number * 20);
        setLoading(true);
    }

    const handleSearchChange = (e, value) => {
        setSearchCriteria(value);
        setSearchLoading(true)

        setTimeout(() => {
            setBegin(0);
            setEnd(20);
            setActivePage(1);
            setSearchLoading(false);
            setDisplayedSsoDomains(ssoDomains.filter(i => i.domain.includes(value.toLowerCase())).slice(0, 20));
            setSearchedSsoDomains(ssoDomains.filter(i => i.domain.includes(value.toLowerCase())));
        }, 500);
    }

    const handleModalClose = () => {
        setModalOpened(!modalOpened);
        setExistedSsoDomain(undefined);
    }

    const handleCreateModalOpen = () => {
        setIsNewModal(true)
        setModalOpened(true)
    }

    const handleEditModalOpen = (el) => {
        setIsNewModal(false)
        setExistedSsoDomain(el)
        setModalOpened(true)
    }

    const refreshForm = () => {
        setExistedSsoDomain(undefined);
    }

    const handleOpenRemoveSsoModal = (ssoId) => {
        setRemoveSsoModalOpen(true);
        setIdToBeDeleted(ssoId);
    }

    const handleSsoRemove = () => {
        dispatch(deleteSsoDomainRoutine({id: idToBeDeleted}));
        setRemoveSsoModalOpen(false);
    }

    return (displayedSsoDomains && displayedSsoDomains.length) || searchCriteria || isLoading ? (
            <div  className="sso-table-wrapper">
                <Search
                    loading={searchLoading}
                    onSearchChange={(e, data) => handleSearchChange(e, data.value)}
                    results={[]}
                    value={searchCriteria}
                />
                <Table celled className="sso-table" >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell rowSpan='2'>Id</Table.HeaderCell>
                            <Table.HeaderCell rowSpan='3'>Domain</Table.HeaderCell>
                            <Table.HeaderCell rowSpan='3'>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            displayedSsoDomains.map((el: SsoDomain, index: number) => (
                                <Table.Row key={el.id}>
                                    <Table.Cell>
                                        {el.id}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {el.domain}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Button secondary onClick={() => handleOpenRemoveSsoModal(el.id)}>
                                            <Icon name="delete" />
                                        </Button>
                                        <Button primary onClick={() => handleEditModalOpen(el)}>
                                            <Icon name="edit" />
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            ))
                        }
                    </Table.Body>
                </Table>
                <Pagination
                    siblingRange={3}
                    defaultActivePage={1}
                    totalPages={Math.ceil(searchedSsoDomains.length ? searchedSsoDomains.length / 20 : ssoDomains.length / 20)}
                    onPageChange={moveNextPage}
                />
                <SsoModal
                    modalOpened={modalOpened}
                    closeModal={handleModalClose}
                    isNew={isNewModal}
                    existedSsoDomain={existedSsoDomain}
                    refreshForm={refreshForm}
                    ssoDomains={ssoDomains}
                />
                <ConfirmModal modalOpened={removeSsoModalOpen}
                              closeModal={() => setRemoveSsoModalOpen(false)}
                              onConfirmAction={handleSsoRemove}
                              label={"Are you sure you want to delete this SSO domain?"} />
                <div className="btn-container">
                    <Button className="create-sso" onClick={handleCreateModalOpen}>
                        Add SSO domain
                    </Button>
                </div>
            </div>
        )
        : (<Loader active />)
}

export default SsoTable;
