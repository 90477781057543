import React from 'react';
import { Modal, Header, Button } from 'semantic-ui-react';

export interface ConfirmModalProps {
    modalOpened: boolean;
    closeModal: () => void;
    onConfirmAction: () => void;
    label: string;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({ modalOpened, closeModal, onConfirmAction, label }) => {

    return (
        <Modal size='small' open={modalOpened} onClose={closeModal}>
            <Header icon='delete' content='Confirmation modal' />
            <Modal.Content><p>{label}</p></Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={closeModal}> No </Button>
                <Button color='black' onClick={onConfirmAction}> Yes </Button>
            </Modal.Actions>
        </Modal>
    )
}