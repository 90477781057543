import {Routine} from "redux-saga-routines";
import {
    createSsoDomainRoutine,
    deleteSsoDomainRoutine,
    getSsoDomainsRoutine,
    updateSsoDomainRoutine
} from "../../routines";

export const ssoReducer = (state: any = { source: [], isLoading: false }, action: Routine<any>) => {
    switch (action.type) {
        case getSsoDomainsRoutine.TRIGGER:
            return {
                ...state,
                isLoading: true
            }

        case getSsoDomainsRoutine.SUCCESS:
            return {
                source: action.payload,
                isLoading: false
            }

        case createSsoDomainRoutine.SUCCESS:
            return {
                source: [...state.source, {id: '...', domain: action.payload.domain}],
            }

        case updateSsoDomainRoutine.SUCCESS:
            const index = state.source.findIndex(x => x.id === action.payload.id);

            return {
                source: [
                    ...state.source.slice(0, index),
                    action.payload,
                    ...state.source.slice(index + 1)
                ]
            }

        case deleteSsoDomainRoutine.SUCCESS:
            return {
                source: state.source.filter(x => x.id !== action.payload.id),
            }

        default:
            return state;
    }
}