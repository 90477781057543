import React, { useState } from 'react';
import { SidebarContent } from './children/sidebar-content/sidebar-context';
import { SidebarIcon } from './children/sidebar-icon';
import { ISidebarProps } from './interfaces/sidebar-item';
import './styles.scss';

export const SideBar: React.FC<ISidebarProps> = ({ items, name }) => {
    const [isOpen, setIsOpen] = useState(true);

    const toogleSidebar = () => {
        setIsOpen(!isOpen);
    }

    return (
        <div className='animation-container'>
            <div className='sidebar-container' style={{
                backgroundColor: isOpen ? 'lightgray' : '#F1F1F1',
                position: isOpen ? 'relative' : 'absolute',
                height: !isOpen ? 'calc(100ch - 95px)' : ''
            }}>
                <div className='sidebar-icon'>
                    <SidebarIcon
                        isOpen={isOpen}
                        handleClick={toogleSidebar}
                        name={name}
                    />
                </div>
                <SidebarContent
                    items={items}
                    isOpen={isOpen}
                />
            </div>
        </div>

    )
}