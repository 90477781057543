import React  from 'react';
import { Modal, Header, Button } from 'semantic-ui-react';
import {useDispatch} from "react-redux";
import {deleteAnnouncementRoutine} from "../../../../redux/routines";

import "./styles.scss";

export interface DeleteAnnouncementModalProps {
    modalOpened: boolean;
    closeModal: () => void;
    id: number | null;
}

export const DeleteAnnouncementModal: React.FC<DeleteAnnouncementModalProps> = ({
    modalOpened,
    closeModal,
    id
}) => {

    const dispatch = useDispatch();

    const removeAnnouncement = () => {
        dispatch(deleteAnnouncementRoutine({id}));
        closeModal();
    }

    return (
        <Modal
            size='small'
            open={modalOpened}
            onClose={closeModal}>
            <Header icon='delete' content="Delete Announcement" />
            <Modal.Content>
                <p>
                    Are you sure you wish to delete this announcement?
                </p>
            </Modal.Content>
            <Modal.Actions>
                <div className="remove-announcement-buttons">
                    <Button color='green' onClick={removeAnnouncement}>
                        Confirm
                    </Button>
                    <Button color='red' onClick={closeModal}>
                        Cancel
                    </Button>
                </div>
            </Modal.Actions>
        </Modal>
    )
}
