import React from 'react';
import ReactDOM from 'react-dom';
import createSagaMidddelware from "redux-saga";
import { createStore, applyMiddleware, compose } from "redux";
import rootSaga from './redux/rootSaga';
import reducers from '../src/redux/rootReducer';
import 'semantic-ui-css/semantic.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import './index.scss'
import App from './components/router/app';
import env from './env';
import { EnvironmentType } from './common/types/envType';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

const sagaMiddelware = createSagaMidddelware();

const composeEnhancer = (env.envType === EnvironmentType.local
  ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : null) || compose

const store = createStore(
  reducers,
  composeEnhancer(applyMiddleware(sagaMiddelware))
);

sagaMiddelware.run(rootSaga);

ReactDOM.render(<App store={store} />, document.getElementById('root'));
