import React from "react";

import "./styles.scss";

export interface ISystemOverloadPopupProps {
    systemOverloadPopupVisible: boolean;
    showSystemOverloadPopup: (value: boolean) => void;
}

const SystemOverloadPopup: React.FC<ISystemOverloadPopupProps> = ({ systemOverloadPopupVisible, showSystemOverloadPopup}) => {
    const position = systemOverloadPopupVisible ? "visible" : "hidden";

    const onClosePopup = () => {
        showSystemOverloadPopup(false);
    }

    return (
        <div className={`system-overload-popup ${position}`}>
            <div className="system-overload-popup__top">
                <button className="system-overload-popup__close-button" onClick={onClosePopup}>
                    <img src="./icons/close.svg" alt="close"/>
                </button>
            </div>
            <p className="system-overload-popup__message"><b>Sorry!</b><br/>
               You requested too large amount of data and some options were blocked to prevent system overload
            </p>
        </div>
    )
}

export default SystemOverloadPopup;
