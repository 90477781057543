import React from 'react';
import { Group } from '../../../../common/types/group';
import { Modal, Grid, Icon, Table } from 'semantic-ui-react';
import { Restriction } from '../../../../common/types/restriction';
import { connect } from 'react-redux';
import Select from 'react-select';
import './styles.scss'
import { State } from '../../../../common/types/state';

export interface RestrictionsModalProps {
    restrictions: Restriction;
    modalOpened: boolean;
    closeModal: () => void;
}

const RestrictionModal: React.FC<RestrictionsModalProps> = ({ restrictions, modalOpened, closeModal }) => {

    const renderIcon = (property: boolean) => {
        return property
            ?
            <Table.Cell>
                <Icon color='green' name='checkmark' size='large' />
            </Table.Cell>
            :
            <Table.Cell>
                <Icon color='red' name='stop circle' size='large' />
            </Table.Cell>
    };

    const renderDomainOnlyAccess = (enable: boolean) => {
        if (enable) {
            return <p className="select-label"> Domain-only access: {restrictions.domainsWithAccess}</p>
        }
    };

    return (
        <Modal
            open={modalOpened}
            onClose={closeModal}>
            <Modal.Header>Group restrictions</Modal.Header>
            <Modal.Content>
                <Grid>
                    <Grid.Column width={7}>
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        Restriction
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Value
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>
                                        HD Live
                                    </Table.Cell>
                                    {
                                        renderIcon(restrictions.isHdPlayerEnabled)
                                    }
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        SD Live
                                    </Table.Cell>
                                    {
                                        renderIcon(restrictions.isSdPlayerEnabled)
                                    }
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        HD Review
                                    </Table.Cell>
                                    {
                                        renderIcon(restrictions.isHdHlsEnabled)
                                    }
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        SD Review
                                    </Table.Cell>
                                    {
                                        renderIcon(restrictions.isSdHlsEnabled)
                                    }
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        HD VOD
                                    </Table.Cell>
                                    {
                                        renderIcon(restrictions.isHdVodEnabled)
                                    }
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        SD VOD
                                    </Table.Cell>
                                    {
                                        renderIcon(restrictions.isSdVodEnabled)
                                    }
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        RTMP Link Access
                                    </Table.Cell>
                                    {
                                        renderIcon(restrictions.isRtmpEnabled)
                                    }
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        Max Players
                                    </Table.Cell>
                                    {
                                        renderIcon(restrictions.isMaxPlayersEnabled)
                                    }
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        Schedule Download
                                    </Table.Cell>
                                    {
                                        renderIcon(restrictions.isDocumentsDownloadEnabled)
                                    }
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        VOD Download
                                    </Table.Cell>
                                    {
                                        renderIcon(restrictions.isStreamsDownloadEnabled)
                                    }
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        Domain-only Access
                                    </Table.Cell>
                                    {
                                        renderIcon(restrictions.isDomainOnlyEnabled)
                                    }
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        Sky Boxes
                                    </Table.Cell>
                                    {
                                        renderIcon(restrictions.isSkyBoxesAllowed)
                                    }
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        Internal Streams
                                    </Table.Cell>
                                    {
                                        renderIcon(restrictions.isInternalStreamsAllowed)
                                    }
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        24 hr Sport News
                                    </Table.Cell>
                                    {
                                        renderIcon(restrictions.is24HrEnabled)
                                    }
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                    <Grid.Column verticalAlign="middle" width={8}>
                        <div className="restrictions-modal_selects">
                            <p className="select-label"> {restrictions.isRequiredSport ? 'Required Sports' : 'Disabled Sports'}</p>
                            <Select
                                components={{ DropdownIndicator: null }}
                                isMulti
                                isDisabled
                                placeholder='Empty'
                                value={restrictions.disabledSports.map(s => {
                                    return {
                                        value: s,
                                        label: s
                                    }
                                })}
                            />
                            <p className="select-label"> {restrictions.isRequiredCompetition ? 'Required Competitions' : 'Disabled Competitions'}</p>
                            <Select
                                components={{ DropdownIndicator: null }}
                                placeholder='Empty'
                                isMulti
                                isDisabled
                                value={restrictions.disabledCompetitions.map(s => {
                                    return {
                                        value: s,
                                        label: s
                                    }
                                })}
                            />

                            {renderDomainOnlyAccess(restrictions.isDomainOnlyEnabled)}

                        </div>
                    </Grid.Column>
                </Grid>

            </Modal.Content>
        </Modal>
    )
}

const mapStateToProps = (state: State) => {
    return {
        restrictions: (state.groups.source as Group).restriction
    }
}

export default connect(mapStateToProps)(RestrictionModal);
