import React from 'react';
import { Icon } from 'semantic-ui-react';
import './styles.scss'

export interface ISidebarIconProps {
    handleClick: () => void;
    isOpen: boolean;
    name: string;
}

export const SidebarIcon: React.FC<ISidebarIconProps> = ({ handleClick, isOpen, name }) => {
    return (
        <div
            className='sidebar-icon-text'
            onClick={handleClick}>
            <Icon
                name={isOpen ? 'close' : 'bars'}
                size={!isOpen ? 'large' : 'small'}
            />
            {!isOpen && name}
        </div>
    )
}