import {all, call, put, takeLatest} from "redux-saga/effects";
import {
    createAnnouncementRoutine,
    deleteAnnouncementRoutine,
    getAnnouncementsRoutine,
    updateAnnouncementRoutine
} from "../routines";
import {isSuccessfulRequest} from "../../utils/helpers";
import {toastr} from "react-redux-toastr";
import {
    createAnnouncementRequest, 
    deleteAnnouncementRequest,
    getAnnouncementsRequest,
    updateAnnouncementRequest
} from "../../services/announcements.service";

function* getAnnouncements(action: any) {
    try {
        const response = yield call(getAnnouncementsRequest);
        yield put(getAnnouncementsRoutine.success(response.data));
    } catch (err) {
        yield put(getAnnouncementsRoutine.failure(err));
    }
}

function* createAnnouncement(action: any) {
    try {
        const response = yield call(createAnnouncementRequest, action.payload.model);
        if(isSuccessfulRequest(response)) {
            toastr.success("Success", "Notification has been created.");
            yield put(createAnnouncementRoutine.success(action.payload));
            yield put(getAnnouncementsRoutine.trigger())
        } else {
            toastr.error("Error", response.data);
            yield put(createAnnouncementRoutine.failure());        }
    } catch (err) {
        yield put(createAnnouncementRoutine.failure(err));
    }
}

function* updateAnnouncement(action: any) {
    try {
        const response = yield call(updateAnnouncementRequest, action.payload.id, action.payload.model);
        if(isSuccessfulRequest(response)) {
            toastr.success("Success", "Notification has been updated.");
            yield put(updateAnnouncementRoutine.success(action.payload));
        } else {
            toastr.error("Error", response.data);
            yield put(updateAnnouncementRoutine.failure());
        }
    } catch (err) {
        yield put(updateAnnouncementRoutine.failure(err));
    }
}

function* deleteAnnouncement(action: any) {
    try {
        const response = yield call(deleteAnnouncementRequest, action.payload.id);
        if(isSuccessfulRequest(response)) {
            toastr.success("Success", "Notification has been deleted");
            yield put(deleteAnnouncementRoutine.success(action.payload));
        } else {
            toastr.error("Error", response.data);
            yield put(deleteAnnouncementRoutine.failure());
        }
    } catch (err) {
        yield put(deleteAnnouncementRoutine.failure(err));
    }
}

export default function* announcementsSaga() {
    yield all([
        takeLatest(getAnnouncementsRoutine, getAnnouncements),
        takeLatest(createAnnouncementRoutine, createAnnouncement),
        takeLatest(updateAnnouncementRoutine, updateAnnouncement),
        takeLatest(deleteAnnouncementRoutine, deleteAnnouncement)
    ]);
}