import {
    getGroups,
    postGroup,
    putGroup,
    deleteGroup as deleteItemCall,
    getGroup,
    restoreGroupRequest
} from "../../services/groups.service";
import {call, put, all, takeLatest} from "redux-saga/effects";
import {
    fetchGroup,
    fetchGroups,
    createGroup,
    putGroups,
    deleteGroup as deleteRoutine,
    removeOrRestoreUserByEmailRoutine,
    removeUserRoutine,
    addUserToGroup,
    updateUserInGroup, restoreGroupRoutine
} from '../routines/index';
import { removeOrRestoreUserByEmailRequest, deleteUser as deleteUserItemCall, postUser, updateUser} from "../../services/user.service";
import {toastr} from "react-redux-toastr";
import {isSuccessfulRequest} from "../../utils/helpers";

function* getAllGroups(action: any) {
    try {
        const response = yield call(getGroups);
        yield put(fetchGroups.success(response.data));
    } catch (err) {
        yield put(fetchGroups.failure(err));
    }
}

function* getSingleGroup(action: any) {
    try {
        const response = yield call(getGroup, action.payload);
        yield put(fetchGroup.success(response.data));
    } catch (err) {
        yield put(fetchGroup.failure(err));
    }
}

function* addGroup(action: any) {
    try {
        const response = yield call(postGroup, action.payload);
        if(isSuccessfulRequest(response)) {
            toastr.success("Success", "Group has been created.");
            yield put(createGroup.success(response.data));
            yield put(fetchGroups.trigger());
        } else {
            toastr.error("Error", response.data);
            yield put(createGroup.failure());
        }
    } catch (err) {
        yield put(createGroup.failure(err));
    }
}

function* updateGroup(action: any) {
    try {
        const response = yield call(putGroup, action.payload);
        if(isSuccessfulRequest(response)) {
            toastr.success("Success", "Group has been updated.");
            yield put(putGroups.success(response.data));
            yield put(fetchGroups.trigger());
        } else {
            toastr.error("Error", response.data);
            yield put(putGroups.failure());
        }
    } catch (err) {
        yield put(putGroups.failure(err));
    }
}

function* deleteGroup(action: any) {
    try {
        const response = yield call(deleteItemCall, action.payload);
        if(isSuccessfulRequest(response)) {
            const message = action.payload.hardDelete ? "Group has been removed." : "Group has been removed with an ability to be restored.";
            toastr.success("Success", message);
            yield put(deleteRoutine.success(action.payload));
        } else {
            toastr.error("Error", response.data);
            yield put(deleteRoutine.failure());
        }
    } catch (err) {
        yield put(deleteRoutine.failure(err));
    }
}

function* restoreGroup(action: any) {
    try {
        const response = yield call(restoreGroupRequest, action.payload);
        if(isSuccessfulRequest(response)) {
            toastr.success("Success", "Group has been restored.");
            yield put(restoreGroupRoutine.success(action.payload));
        } else {
            toastr.error("Error", response.data);
            yield put(restoreGroupRoutine.failure());
        }
    } catch (err) {
        yield put(restoreGroupRoutine.failure(err));
    }
}

function* removeOrRestoreUserFromGroup(action: any) {
    try {
        const response = yield call(removeOrRestoreUserByEmailRequest, action.payload);
        if(isSuccessfulRequest(response)) {
            const message = action.payload.deleted ? "User has been removed with an ability to be restored." : "User has been restored.";
            toastr.success("Success", message);
            yield put(removeOrRestoreUserByEmailRoutine.success(response.data));
        } else {
            toastr.error("Error", response.data);
            yield put(removeOrRestoreUserByEmailRoutine.failure());
        }
    } catch (err) {
        yield put(removeOrRestoreUserByEmailRoutine.failure(err.message));
    }
}

function* removeUserFromGroup(action: any) {
    try {
        const response = yield call(deleteUserItemCall, action.payload);
        if (isSuccessfulRequest(response)) {
            toastr.success("Success", "User has been removed.");
            yield put(removeUserRoutine.success(action.payload.id));
        } else {
            toastr.error("Error", response.data);
            yield put(removeUserRoutine.failure());
        }
    } catch (err) {
        yield put(removeUserRoutine.failure(err.message));
    }
}

function* addUserToGroupSaga(action: any) {
    try {

        const response = yield call(postUser, action.payload);
        if(!response.data) {
            throw Error('Incorrect data');
        }

        if(response.data.error) {
            toastr.error('Error', response.data.error)
        }
        else {
            toastr.success('Success', 'Created user should receive confirmation email!')
        }

        //Remove unnecessary restrictions
        const {restrictions, ...data} = response.data;

        yield put(addUserToGroup.success(data));
    } catch (err) {
        yield put(addUserToGroup.failure(err.message));
    }
}

function* updateUserInGroupSaga(action: any) {
    try {
        const response = yield call(updateUser, action.payload);
        if(!response.data) {
            throw Error('Incorrect data');
        }

        //Remove unnecessary restrictions
        const {restrictions, ...data} = response.data;

        yield put(updateUserInGroup.success(data));
    } catch (err) {
        yield put(updateUserInGroup.failure(err.message));
    }
}

export default function* groupsSaga() {
    yield all([
        takeLatest(createGroup, addGroup),
        takeLatest(putGroups, updateGroup),
        takeLatest(fetchGroup, getSingleGroup),
        takeLatest(fetchGroups, getAllGroups),
        takeLatest(deleteRoutine, deleteGroup),
        takeLatest(removeOrRestoreUserByEmailRoutine, removeOrRestoreUserFromGroup),
        takeLatest(removeUserRoutine, removeUserFromGroup),
        takeLatest(addUserToGroup, addUserToGroupSaga),
        takeLatest(updateUserInGroup, updateUserInGroupSaga),
        takeLatest(restoreGroupRoutine, restoreGroup)
    ]);
}
