import { Routine } from 'redux-saga-routines';
import { fetchUser } from '../../routines';

export const userReducer = (state = null, action: Routine<any>) => {
    switch (action.type) {
        case fetchUser.SUCCESS:
            return action.payload;
        default:
            return state;
    }
}
