import client from '../utils/client';
import env from '../env';
import { handleError } from './../utils/client';

export const getSports = () => {
    return client.get(`${env.apiUrl}/api/schedule/sports`).catch(handleError);
}

export const getCountries = () => {
    return client.get(`${env.apiUrl}/api/schedule/countries`).catch(handleError);
}