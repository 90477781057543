import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { State } from '../../common/types/state';
import env from '../../env';

const PrivateRoute = ({ component: Component, roles, ...rest }: any) => (
    <Route {...rest} render={props => {
        const currentUser = rest.user;
        const token = localStorage.getItem('token');

        if (!currentUser && !token) {
            const initPath = window.location.href;
            window.location.href = (`${env.inplayUrl}/login?redirectUrl=${initPath}`);
        }
        if (roles && roles.indexOf(currentUser.role) === -1) {
            return <Redirect to={{ pathname: '/' }} />
        }
        return <Component {...props} />
    }} />
)

const mapStateToProps = (state: State) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(PrivateRoute);



