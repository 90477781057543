import { handleError } from './../utils/client';
import { UserRegisterModel } from "../common/types/userRegisterModel";
import client from '../utils/client';
import env from '../env';
import { SwitchRestriction } from '../common/types/switchRestriction';
import { IUserDelete } from '../common/types/userDelete';


export const postUser = (user: UserRegisterModel) => {
    return client.post(`${env.apiUrl}/api/users`, user).catch(handleError);
}

export const updateUser = (user: UserRegisterModel) => {
    return client.put(`${env.apiUrl}/api/users`, user).catch(handleError);
}

export const getCurrentUser = () => {
    return client.get(`${env.apiUrl}/api/users/current`).catch(handleError);
}

export const removeOrRestoreUserByEmailRequest = (model: IUserDelete) => {
    return client.put(`${env.apiUrl}/api/users/delete`, model).catch(handleError);
}

export const deleteUser = (x: { id: string }) => {
    return client.delete(`${env.apiUrl}/api/users/${x.id}/hard`).catch(handleError);
}

export const switchIndividualRestrictions = (restriction: SwitchRestriction) => {
    return client.put(`${env.apiUrl}/api/users/notifications`, restriction).catch(handleError);
}

export const switchExternalUserRequest = (userId: number) => {
    return client.put(`${env.apiUrl}/api/users/${userId}/plain-login`).catch(handleError);
}

export const getRoles = () => {
    return client.get(`${env.apiUrl}/api/users/available_roles`).catch(handleError);
}

export const forgotPassword = (data: { email: string }) => {
    return client.put(`${env.apiUrl}/api/users/initiate/reset_password`, data);
}
