import {all, call, put, takeLatest} from "redux-saga/effects";
import {
    createSsoDomainRequest,
    deleteSsoDomainRequest,
    getSsoDomainsRequest,
    updateSsoDomainRequest
} from "../../services/sso.service";
import {
    
    createSsoDomainRoutine,
    deleteSsoDomainRoutine,
    getSsoDomainsRoutine,
    updateSsoDomainRoutine
} from "../routines";
import {isSuccessfulRequest} from "../../utils/helpers";
import {toastr} from "react-redux-toastr";

function* getSsoDomains(action: any) {
    try {
        const response = yield call(getSsoDomainsRequest);
        yield put(getSsoDomainsRoutine.success(response.data));
    } catch (err) {
        yield put(getSsoDomainsRoutine.failure(err));
    }
}

function* createSsoDomain(action: any) {
    try {
        const response = yield call(createSsoDomainRequest, action.payload);
        if(isSuccessfulRequest(response)) {
            toastr.success("Success", "Whitelist domain has been created.");
            yield put(createSsoDomainRoutine.success(action.payload));
            yield put(getSsoDomainsRoutine.trigger())
        } else {
            toastr.error("Error", response.data);
            yield put(createSsoDomainRoutine.failure());        }
    } catch (err) {
        yield put(createSsoDomainRoutine.failure(err));
    }
}

function* updateSsoDomain(action: any) {
    try {
        const response = yield call(updateSsoDomainRequest, action.payload);
        if(isSuccessfulRequest(response)) {
            toastr.success("Success", "Whitelist domain has been updated.");
            yield put(updateSsoDomainRoutine.success(action.payload));
        } else {
            toastr.error("Error", response.data);
            yield put(updateSsoDomainRoutine.failure());
        }
    } catch (err) {
        yield put(updateSsoDomainRoutine.failure(err));
    }
}

function* deleteSsoDomain(action: any) {
    try {
        const response = yield call(deleteSsoDomainRequest, action.payload.id);
        if(isSuccessfulRequest(response)) {
            toastr.success("Success", "Whitelist domain has been deleted");
            yield put(deleteSsoDomainRoutine.success(action.payload));
        } else {
            toastr.error("Error", response.data);
            yield put(deleteSsoDomainRoutine.failure());
        }
    } catch (err) {
        yield put(deleteSsoDomainRoutine.failure(err));
    }
}

export default function* ssoSaga() {
    yield all([
        takeLatest(getSsoDomainsRoutine, getSsoDomains),
        takeLatest(createSsoDomainRoutine, createSsoDomain),
        takeLatest(updateSsoDomainRoutine, updateSsoDomain),
        takeLatest(deleteSsoDomainRoutine,deleteSsoDomain)
    ]);
}