import React, { useEffect } from 'react';
import { User } from '../../../../common/types/user';
import { State } from '../../../../common/types/state';
import env from '../../../../env';
import StreamSettingsTable from '../stream-settings-table/stream-settings-table';
import { connect } from 'react-redux';

export interface IStreamSettingsPageProps {
    user: User;
}

const StreamSettingsPage: React.FC<IStreamSettingsPageProps> = ({ user }) => {
    useEffect(() => {

        if (!user)
            window.location.href = `${env.inplayUrl}/logout`
    });

    return (
        <div className="table-container">
            <h2>Stream Settings Management Page</h2>
            <StreamSettingsTable />
        </div>
    )
}

const mapStateToProps = (state: State) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(StreamSettingsPage);