import { Routine } from 'redux-saga-routines';
import { fetchCompetitions } from '../../routines';

export const competitionsReducer = (state: any[] = [], action: Routine<any>) => {
    switch (action.type) {
        case fetchCompetitions.SUCCESS:
            return action.payload
        default:
            return state
    }
}
