import React, { useEffect, useState } from 'react';
import { Table, Loader, Pagination, PaginationProps, Icon, Button, Search } from 'semantic-ui-react';
import { StreamSetting } from '../../../../common/types/stream-setting';
import { State } from '../../../../common/types/state';
import { getStreamSettingsRoutine, deleteStreamSettingRoutine } from '../../../../redux/routines';
import { connect } from 'react-redux';
import StreamSettingsModal from '../stream-settings-modal/stream-settings-modal';
import './stream-settings-table.scss';
import {ConfirmModal} from "../../../../common/components/confirm-model";

export interface StreamSettingsTableProps {
    streamSettings: StreamSetting[];
    getStreamSettingsRoutine: () => void;
    deleteStreamSettingRoutine: (id?: number) => void;
}

const StreamSettingsTable: React.FC<StreamSettingsTableProps> = (props) => {
    const [activePage, setActivePage] = useState(1);
    const [begin, setBegin] = useState(0);
    const [end, setEnd] = useState(20);
    const [localStreamSettings, setLocalStreamSettings] = useState([] as any);
    const [modalOpened, setModalOpened] = useState(false);
    const [isNewModal, setIsNewModal] = useState(false);
    const [existedStreamSetting, setExistedStreamSetting] = useState(undefined as any)
    const [loading, setLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [searchCriteria, setSearchCriteria] = useState('');
    const [searchedStreamSettings, setSearchedStreamSettings] = useState([] as any);
    const [removeStreamSettingModalOpen, setRemoveStreamSettingModalOpen] = useState(false);
    const [idToBeDeleted, setIdToBeDeleted] = useState<number | undefined>();

    const { streamSettings, getStreamSettingsRoutine, deleteStreamSettingRoutine } = props;

    useEffect(() => {
        getStreamSettingsRoutine();
    }, [])

    useEffect(() => {
        setLocalStreamSettings(streamSettings && streamSettings.length ? streamSettings.slice(begin, end) : []);
    }, [streamSettings])

    useEffect(() => {
        if (streamSettings) {
            setLocalStreamSettings(streamSettings.slice(begin, end));
            if (searchedStreamSettings.length) {
                setLocalStreamSettings(searchedStreamSettings.slice(begin, end));
            }
        }
        setLoading(false)
    }, [loading])

    const moveNextPage = (event: React.MouseEvent<HTMLAnchorElement>, data: PaginationProps) => {
        setActivePage(data.activePage as number);
        setBegin(data.activePage as number * 20 - 20);
        setEnd(data.activePage as number * 20);
        setLoading(true);
    }

    const handleSearchChange = (e, value) => {
        setSearchCriteria(value);
        setSearchLoading(true)

        setTimeout(() => {
            setBegin(0);
            setEnd(20);
            setActivePage(1);
            setSearchLoading(false);
            setLocalStreamSettings(streamSettings.filter(i =>
                i.channelAlias.toLowerCase().includes(value.toLowerCase())
            ).slice(0, 20));
            setSearchedStreamSettings(streamSettings.filter(i =>
                i.channelAlias.toLowerCase().includes(value.toLowerCase())
            ));
        }, 500);
    }

    const handleOpenRemoveStreamSettingModal = (settingId) => {
        setRemoveStreamSettingModalOpen(true);
        setIdToBeDeleted(settingId);
    }

    const handleSettingRemove = () => {
        deleteStreamSettingRoutine(idToBeDeleted);
        setRemoveStreamSettingModalOpen(false);
    }

    return (localStreamSettings && localStreamSettings.length) || searchCriteria ? (
        <div>
            <Search
                loading={searchLoading}
                onSearchChange={(e, data) => handleSearchChange(e, data.value)}
                results={[]}
                value={searchCriteria}
                {...props}
            />
            <Table celled className="competitions-table" >
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell rowSpan='1'>Id</Table.HeaderCell>
                        <Table.HeaderCell rowSpan='2'>Channel Alias</Table.HeaderCell>
                        <Table.HeaderCell rowSpan='2'>HLS Server Alias</Table.HeaderCell>
                        <Table.HeaderCell rowSpan='2'>RTMP Server Alias</Table.HeaderCell>
                        <Table.HeaderCell rowSpan='2'>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        localStreamSettings.map((el: StreamSetting) => (
                            <Table.Row key={el.channelAlias}>
                                <Table.Cell>
                                    {el.id}
                                </Table.Cell>
                                <Table.Cell>
                                    {el.channelAlias}
                                </Table.Cell>
                                <Table.Cell>
                                    {el.serverAlias}
                                </Table.Cell>
                                <Table.Cell>
                                    {el.rtmpServerAlias}
                                </Table.Cell>
                                <Table.Cell>
                                    <Button secondary onClick={() => {
                                        handleOpenRemoveStreamSettingModal(el.id);
                                    }}>
                                        <Icon name="delete" />
                                    </Button>
                                    <Button primary onClick={() => {
                                        setIsNewModal(false)
                                        setExistedStreamSetting(el)
                                        setModalOpened(true)
                                    }}>
                                        <Icon name="edit" />
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                        ))
                    }
                </Table.Body>
            </Table>
            <Pagination
                siblingRange={3}
                defaultActivePage={1}
                totalPages={Math.ceil(searchedStreamSettings.length ? searchedStreamSettings.length / 20 : streamSettings.length / 20)}
                onPageChange={moveNextPage}
            />
            <StreamSettingsModal
                modalOpened={modalOpened}
                closeModal={() => setModalOpened(false)}
                isNew={isNewModal}
                existedStreamSetting={existedStreamSetting}
            />
            <ConfirmModal modalOpened={removeStreamSettingModalOpen}
                          closeModal={() => setRemoveStreamSettingModalOpen(false)}
                          onConfirmAction={handleSettingRemove}
                          label={"Are you sure you want to delete this stream setting?"} />
            <div className="btn-container">
                <Button className="create-ss" onClick={() => {
                    setIsNewModal(true)
                    setModalOpened(true)
                }}>
                    Create stream setting
                </Button>
            </div>
        </div>
    )
        : (<Loader active />)
}

const mapStateToProps = (state: State) => {
    return {
        streamSettings: state.streamSettings
    }
}

const mapDispathToProps = {
    getStreamSettingsRoutine,
    deleteStreamSettingRoutine
}

export default connect(mapStateToProps, mapDispathToProps)(StreamSettingsTable)
