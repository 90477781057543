import axios, { AxiosError } from 'axios';
import env from '../env';
import {toastr} from "react-redux-toastr";

const client = axios.create({
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Content-Encoding': 'identity'
    }
})

client.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) config.headers['Authorization'] = `Bearer ${token}`;
        config.headers['Timezone-Offset'] = -new Date().getTimezoneOffset();
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
)

export const redirectToLogin = () => {
    localStorage.clear();
    window.location.href = `${env.inplayUrl}/login`;
}

const tryProcessErrorMessage = (error: AxiosError): boolean => {
    const { response: resp } = error;

    // Should Handle Token Refresh
    if (error.config && resp && resp.status === 401) {
        return false;
    }

    if (!resp) {
        return false;
    }

    return true;
}

export const handleError = async (error: AxiosError) => {

    if (error.response?.status === 403) {
        toastr.error("Error!", "You are not allowed to perform this action!");
        return;
    }

    if (tryProcessErrorMessage(error)) {
        return error.response;
    }

    redirectToLogin();
}

export default client;
