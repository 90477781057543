import React from 'react';
import { Dropdown } from 'semantic-ui-react';

interface IClientsDropsDownProps {
    possibleOptions: any[];
    onChange: (array: any) => void;
    isSingle?: boolean;
    values: string[];
}

const ClientsDropdown: React.FC<IClientsDropsDownProps> = ({ possibleOptions, onChange, isSingle, values }) => {

    return <Dropdown
        placeholder='Add clients'
        fluid
        multiple={!isSingle}
        selection
        options={possibleOptions}
        value={values}
        onChange={(_, e) => {
            onChange(e.value as string[]);
        }}
    />
}

export { ClientsDropdown }
