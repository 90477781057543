import React, { useEffect } from 'react';
import { User } from '../../../../common/types/user';
import { State } from '../../../../common/types/state';
import env from '../../../../env';
import {useDispatch, useSelector} from 'react-redux';
import SsoTable from "../sso-table/sso-table";
import {getSsoDomainsRoutine} from "../../../../redux/routines";

export interface ISsoPageProps {}

const SsoPage: React.FC<ISsoPageProps> = () => {

    const user: User = useSelector((state: State) => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSsoDomainsRoutine());
    }, [])

    useEffect(() => {
        if (!user)
            window.location.href = `${env.inplayUrl}/logout`
    });

    return (
        <div className="table-container">
            <h2>SSO Management Page</h2>
            <SsoTable />
        </div>
    )
}

export default SsoPage;