import React, { useEffect, useState, useCallback } from 'react'
import { ServerAlias } from '../../../../common/types/server-alias'
import { State } from '../../../../common/types/state'
import { getServerAliasesRoutine, deleteServerAliasRoutine } from '../../../../redux/routines'
import { connect } from 'react-redux'
import { Loader, Table, Button, Icon } from 'semantic-ui-react'
import ServerAliasesModal from '../server-aliases-modal/server-aliases-modal'
import './server-aliases-table.scss'
import {ConfirmModal} from "../../../../common/components/confirm-model";

export interface ServerAliasesTableProps {
    serverAliases?: ServerAlias[];
    getServerAliases: () => void;
    deleteServerAlias: (id?: number) => void;
}

const ServerAliasesTable: React.FC<ServerAliasesTableProps> = ({ serverAliases, getServerAliases, deleteServerAlias }) => {

    const [modalOpened, setModalOpened] = useState(false);
    const [isNewModal, setIsNewModal] = useState(false);
    const [existedServerAlias, setExistedServerAlias] = useState(undefined as any);
    const [removeServerAliasModalOpen, setRemoveServerAliasModalOpen] = useState(false);
    const [idToBeDeleted, setIdToBeDeleted] = useState<number | undefined>();

    useEffect(() => {
        getServerAliases();
    }, [])

    const handleOpenRemoveStreamSettingModal = (aliasId) => {
        setRemoveServerAliasModalOpen(true);
        setIdToBeDeleted(aliasId);
    }

    const handleAliasRemove = () => {
        deleteServerAlias(idToBeDeleted);
        setRemoveServerAliasModalOpen(false);
    }

    return serverAliases && serverAliases.length ? (
        <div className="table latency-table">
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell rowSpan='1' width={1}>Id</Table.HeaderCell>
                        <Table.HeaderCell rowSpan='2'>HLS Alias</Table.HeaderCell>
                        <Table.HeaderCell rowSpan='2'>RTMP Alias</Table.HeaderCell>
                        <Table.HeaderCell rowSpan='2'>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        serverAliases.map((el: ServerAlias) =>
                            <Table.Row key={`${el.id}${el.serverAlias}`}>
                                <Table.Cell>
                                    {el.id}
                                </Table.Cell>
                                <Table.Cell>
                                    {el.serverAlias}
                                </Table.Cell>
                                <Table.Cell>
                                    {el.rtmpServerAlias}
                                </Table.Cell>
                                <Table.Cell>
                                    <Button secondary onClick={() => {
                                        handleOpenRemoveStreamSettingModal(el.id);
                                    }}>
                                        <Icon name="delete" />
                                    </Button>
                                    <Button primary onClick={() => {
                                        setIsNewModal(false)
                                        setExistedServerAlias(el)
                                        setModalOpened(true)
                                    }}>
                                        <Icon name="edit" />
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                        )
                    }
                </Table.Body>
            </Table>
            <ServerAliasesModal
                modalOpened={modalOpened}
                closeModal={() => {
                    setModalOpened(false)
                }}
                isNew={isNewModal}
                existedServerAlias={existedServerAlias} />
            <ConfirmModal modalOpened={removeServerAliasModalOpen}
                          closeModal={() => setRemoveServerAliasModalOpen(false)}
                          onConfirmAction={handleAliasRemove}
                          label={"Are you sure you want to delete this server alias?"} />
            <div className="btn-container">
                <Button className="create-ss" onClick={() => {
                    setIsNewModal(true)
                    setModalOpened(true)
                }}>
                    Create server alias
                </Button>
            </div>
        </div>
    ) : <Loader active />
}

const mapStateToProps = (state: State) => {
    return {
        serverAliases: state.serverAliases
    }
}

const mapDispatchToProps = {
    getServerAliases: getServerAliasesRoutine,
    deleteServerAlias: deleteServerAliasRoutine
}

export default connect(mapStateToProps, mapDispatchToProps)(ServerAliasesTable)