import React, { useEffect, useState } from 'react';
import { Table, Loader, Pagination, PaginationProps, Icon, Button, Search } from 'semantic-ui-react';
import { State } from '../../../../common/types/state';
import {useSelector} from "react-redux";
import {DeleteAnnouncementModal} from "../delete-announcement-modal/delete-announcement-modal";
import AnnouncementModal, {IAnnouncementFormValues} from "../announcement-modal/announcement-modal";
import "./styles.scss";
import {IAnnouncement} from "../../../../common/types/announcements";
import moment from "moment";

export interface IAnnouncementsTableProps {}

const AnnouncementsTable: React.FC<IAnnouncementsTableProps> = () => {
    const [activePage, setActivePage] = useState(1);
    const [begin, setBegin] = useState(0);
    const [end, setEnd] = useState(20);
    const [displayedAnnouncements, setDisplayedAnnouncements] = useState([] as any);
    const [modalOpened, setModalOpened] = useState(false);
    const [announcementToUpdate, setAnnouncementToUpdate] = useState<IAnnouncement | null>(null)
    const [loading, setLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [searchCriteria, setSearchCriteria] = useState('');
    const [searchedAnnouncements, setSearchedAnnouncements] = useState([] as any);
    const [deleteAnnouncementModalOpen, setDeleteAnnouncementModalOpen] = useState(false);
    const [idToBeDeleted, setIdToBeDeleted] = useState(null);

    const announcements: IAnnouncement[] = useSelector((state: State) => state.announcements.source);
    const isLoading = useSelector((state: State) => state.announcements.isLoading);

    useEffect(() => {
        setDisplayedAnnouncements(announcements && announcements.length ? announcements.slice(begin, end) : []);
    }, [announcements])

    useEffect(() => {
        if (announcements) {
            setDisplayedAnnouncements(announcements.slice(begin, end));
            if (searchedAnnouncements.length) {
                setDisplayedAnnouncements(searchedAnnouncements.slice(begin, end));
            }
        }
        setLoading(false)
    }, [loading])

    const moveNextPage = (event: React.MouseEvent<HTMLAnchorElement>, data: PaginationProps) => {
        setActivePage(data.activePage as number);
        setBegin(data.activePage as number * 20 - 20);
        setEnd(data.activePage as number * 20);
        setLoading(true);
    }

    const handleSearchChange = (e, value) => {
        setSearchCriteria(value);
        setSearchLoading(true)

        setTimeout(() => {
            setBegin(0);
            setEnd(20);
            setActivePage(1);
            setSearchLoading(false);
            setDisplayedAnnouncements(announcements.filter(i => i.title.toLowerCase().includes(value.toLowerCase())).slice(0, 20));
            setSearchedAnnouncements(announcements.filter(i => i.title.toLowerCase().includes(value.toLowerCase())));
        }, 500);
    }

    const handleModalClose = () => {
        setModalOpened(!modalOpened);
        setAnnouncementToUpdate(null);
    }

    const handleCreateModalOpen = () => {
        setModalOpened(true);
    }

    const handleEditModalOpen = (el) => {
        setAnnouncementToUpdate(el);
        setModalOpened(true);
    }

    const refreshForm = () => {
        setAnnouncementToUpdate(null);
    }

    const closeDeleteAnnouncementModal = () => {
        setDeleteAnnouncementModalOpen(false);
    }

    const handleOpenRemoveAnnouncementModal = (id) => {
        setDeleteAnnouncementModalOpen(true);
        setIdToBeDeleted(id);
    }

    return !isLoading ? (
            <div className="announcement-table-wrapper">
                <Search
                    loading={searchLoading}
                    onSearchChange={(e, data) => handleSearchChange(e, data.value)}
                    results={[]}
                    value={searchCriteria}
                />
                <Table celled className="announcement-table" >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell rowSpan='2'>Id</Table.HeaderCell>
                            <Table.HeaderCell rowSpan='3'>Announcement Title</Table.HeaderCell>
                            <Table.HeaderCell rowSpan='3'>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            displayedAnnouncements.map((el: IAnnouncement, index: number) => (
                                <Table.Row key={el.id}>
                                    <Table.Cell>
                                        {el.id}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {el.title}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Button secondary onClick={() => handleOpenRemoveAnnouncementModal(el.id)}>
                                            <Icon name="delete" />
                                        </Button>
                                        <Button primary onClick={() => handleEditModalOpen(el)}>
                                            <Icon name="edit" />
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            ))
                        }
                    </Table.Body>
                </Table>
                <Pagination
                    siblingRange={3}
                    defaultActivePage={1}
                    totalPages={Math.ceil(searchedAnnouncements.length ? searchedAnnouncements.length / 20 : announcements.length / 20)}
                    onPageChange={moveNextPage}
                />
                <AnnouncementModal
                    modalOpened={modalOpened}
                    closeModal={handleModalClose}
                    announcementToUpdate={announcementToUpdate}
                    refreshForm={refreshForm}
                    announcements={announcements}
                />
                <DeleteAnnouncementModal
                    closeModal={closeDeleteAnnouncementModal}
                    modalOpened={deleteAnnouncementModalOpen}
                    id={idToBeDeleted}
                />
                <div className="btn-container">
                    <Button className="create-announcement" onClick={handleCreateModalOpen}>
                        Add Announcement
                    </Button>
                </div>
            </div>
        )
        : (<Loader active />)
}

export default AnnouncementsTable;
