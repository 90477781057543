import client, {handleError} from "../utils/client";
import env from "../env";
import {ICreateAnnouncement} from "../common/types/announcements";

export const getAnnouncementsRequest = () => {
    return client.get(`${env.apiUrl}/api/announcements`).catch(handleError);
}

export const createAnnouncementRequest = (note: ICreateAnnouncement)=> {
    return client.post(`${env.apiUrl}/api/announcements`, note).catch(handleError);
}

export const updateAnnouncementRequest = (id: number, note: ICreateAnnouncement) => {
    return client.put(`${env.apiUrl}/api/announcements/${id}`, note).catch(handleError);
}

export const deleteAnnouncementRequest = (id: number) => {
    return client.delete(`${env.apiUrl}/api/announcements/${id}`).catch(handleError);
}