import React, { useEffect, useState } from 'react';
import { Modal, Header, Button, Input, Checkbox } from 'semantic-ui-react';
import { User } from '../../../../common/types/user';
import { Group } from '../../../../common/types/group';
import { IUserDelete } from '../../../../common/types/userDelete';
import { State } from '../../../../common/types/state';
import { useSelector } from 'react-redux';

export interface DeleteModalProps {
    object?: User | Group;
    modalOpened: boolean;
    closeModal: () => void;
    removeOrRestoreUserAction: (model: IUserDelete) => void;
    removeGroup: (x: { id: any, reason: string, hardDelete: boolean }) => void;
    removeUserAction: (x: { id: any }) => void;
    type: string;
    actionType: string;
}

export const DeleteModal: React.FC<DeleteModalProps> = ({
    modalOpened,
    closeModal,
    removeOrRestoreUserAction,
    removeUserAction,
    removeGroup,
    actionType,
    object,
    type
    }) => {
    const [reason, setReason] = useState(`Manual ${actionType.toLowerCase()}`);
    const [hardDelete, setHardDelete] = useState(false);

    useEffect(() => {
        setReason(`Manual ${actionType.toLowerCase()}`)
    }, [actionType])

    const user = useSelector((state: State) => state.user);

    return (
        <Modal
            size='small'
            open={modalOpened}
            onClose={closeModal}>
            <Header icon='delete' content={`${actionType} ${type}`} />
            <Modal.Content>
                <p>
                    Are you sure you want to {actionType} the {type}?
                </p>
                <Input
                    label='Reason'
                    disabled={hardDelete}
                    value={hardDelete ? '--' : reason}
                    onChange={(e, obj) => setReason(obj.value)}>
                </Input>
                {
                    type == 'Group' ?
                        <Checkbox
                            className='hard-delete-checkbox'
                            label='Hard delete (also deletes users, logs etc.)'
                            checked={hardDelete}
                            onChange={(e, obj) => setHardDelete(obj.checked ?? false)}
                        />
                        : <div />
                }
                {
                    type == 'User'&& (user.role === 'Admin' || user.role === 'Super Admin') && actionType == 'Delete'?
                        <Checkbox
                            className='hard-delete-checkbox'
                            label='Hard delete (also deletes logs, stats etc.)'
                            checked={hardDelete}
                            onChange={(e, obj) => setHardDelete(obj.checked ?? false)}
                        />
                        : <div />
                }

            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={closeModal}>
                    No
                </Button>
                <Button color='black' onClick={() => {
                    if (hardDelete && type == 'User') {
                        removeUserAction({ id: (object as User).id as number })
                        setHardDelete(false);
                    } else {
                        type == 'User'
                            ? removeOrRestoreUserAction({
                                email: (object as User).email,
                                deleted: actionType === 'Delete',
                                reason: reason
                            })
                            : removeGroup({ id: (object as Group).id as number, reason: reason, hardDelete: hardDelete })
                    }

                }}>
                    Yes
                </Button>
            </Modal.Actions>
        </Modal>
    )
}
