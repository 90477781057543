import { Routine } from 'redux-saga-routines';
import {
    removeDeviceRoutine,
    fetchDevicesRoutine,
    removeAllDevicesRoutine,
} from '../../routines';
import { Device } from "../../../common/types/device";

export const userDeviceReducer = (state: Device[] = [], action: Routine<any>) => {
    switch (action.type) {
        case fetchDevicesRoutine.TRIGGER:
            return state;
        case fetchDevicesRoutine.SUCCESS:
            return action.payload;
        case removeDeviceRoutine.SUCCESS:
            return [...state.filter(x => x.uuid !== action.payload.deviceUid)]
        case removeAllDevicesRoutine.SUCCESS:
            return [];
        default:
            return state;
    }
}
