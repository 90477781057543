import React, { useState } from 'react';
import {Tab, Form, Input, InputOnChangeData, Popup, Icon, Dropdown} from 'semantic-ui-react';
import { Formik, FormikProps } from 'formik';
import {IMainInfoProps, IRestrictionProps} from '../../../interfaces/creation-form';
import ImageUploader from 'react-images-upload';
import { DisableConfirmationModal } from './children/disable-confirmation-modal/disable-confirmation-modal';

import "./styles.scss";
import {DateRangePicker} from "react-dates";
import moment from "moment";
import {useSelector} from "react-redux";
import {State} from "../../../../../../common/types/state";

export interface MainInfoTabProps {
    mainInfo: IMainInfoProps;
    onImageDrop: (files: File[]) => void;
    picture: string | null;
    setState: (values: IMainInfoProps) => void;
    focusedInput: any;
    changeFocusedInput: any;
    restrictions: IRestrictionProps;
}

const MainInfoTab: React.FC<MainInfoTabProps> = ({
     mainInfo,
     onImageDrop,
     picture,
     setState,
     focusedInput,
     changeFocusedInput,
     restrictions,
 }) => {
    const [modalOpened, setModalOpened] = useState(false);

    const countries = useSelector((state: State) => state.countries);

    const handleDateChange = (dates, values) => {
        if (Date.parse(dates.startDate) > Date.parse(values.trial.endTime) || !dates.endDate) {
            values.trial = {
                startTime: dates.startDate!,
                endTime: dates.startDate!
            }
            setState(values);
        } else {
            values.trial = {
                startTime: dates.startDate!,
                endTime: dates.endDate!
            }
            setState(values);
        }
    }

    return (
        <Tab.Pane>
            <Formik
                key={'mainInfoForm'}
                initialValues={mainInfo}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                }}
                render={({ values }: FormikProps<IMainInfoProps>) => {

                    const setFieldValue = (data: InputOnChangeData, fieldName: string) => {
                        values[fieldName] = data.value;
                        setState(values);
                    }

                    const setCountryValue = (data: any, fieldName: string) => {
                        values[fieldName] = data;
                        setState(values);
                    }

                    const setCheckboxValue = (props: any, fieldName: string) => {
                        setFieldValue(props.checked, fieldName as any)
                        values[fieldName] = props.checked
                        setState(values);

                        if (fieldName === 'isTrial'
                            && props.checked
                            && restrictions.trial === null) {
                            values.trial = {
                                startTime: moment(),
                                endTime: moment(),
                            }
                            setState(values);
                        }
                    }

                    if (values.isTrial) {
                        if (values.trial === null) {
                            values.trial = {
                                startTime: moment(),
                                endTime: moment(),
                            }
                        } else {
                            values.trial.startTime = moment(values.trial.startTime.toString());
                            values.trial.endTime = moment(values.trial.endTime.toString());
                        }
                    }

                    return <Form size="large">
                        <Form.Group widths='equal'>
                            <Form.Field inline>
                                <Input
                                    label={'Client Name*'}
                                    inline={1}
                                    size="mini"
                                    fluid
                                    key={'name'}
                                    onChange={(_, data) => setFieldValue(data, 'name')}
                                    type="text"
                                    name="name"
                                    placeholder="Client Name"
                                    value={values.name} />
                            </Form.Field>
                            <Form.Field inline>
                                <Input
                                    label={'Contact*'}
                                    inline={1}
                                    size="mini"
                                    fluid
                                    key={'contact'}
                                    onChange={(_, data) => setFieldValue(data, 'contact')}
                                    type="text"
                                    name="contact"
                                    placeholder="Contact"
                                    value={values.contact} />
                            </Form.Field>
                        </Form.Group>
                        <Form.Field inline>
                            <Input
                                type="email"
                                label={'Email*'}
                                name="email"
                                placeholder="Email address"
                                value={values.email}
                                fluid
                                inline={1}
                                size="mini"
                                key={'email'}
                                onChange={(_, data) => setFieldValue(data, 'email')}
                            />
                        </Form.Field>
                        <Form.Field inline>
                            <Input
                                type="email"
                                label={'Admin Email*'}
                                name="clientAdminEmail"
                                placeholder="Admin Email address"
                                value={values.clientAdminEmail}
                                fluid
                                inline={1}
                                size="mini"
                                key={'clientAdminEmail'}
                                onChange={(_, data) => setFieldValue(data, 'clientAdminEmail')}
                            />
                        </Form.Field>
                        <label className="region-label">Region*</label>
                        <Form.Field inline>
                            <Dropdown
                                onChange={(_, data) => setCountryValue(data.value, 'addresses')}
                                placeholder={"Select Country"}
                                fluid
                                search
                                selection
                                multiple={true}
                                value={values.addresses}
                                options={countries && countries.map(s => {
                                    return {
                                        key: s.alpha2Code,
                                        value: s.englishName,
                                        text: s.englishName,
                                    }
                                })}
                            />
                        </Form.Field>
                        <div className="middle-section">
                            <div className="middle-section__left">
                                <Form.Group>
                                    <Form.Field inline>
                                        <Input
                                            label={'Number of Licenses*'}
                                            inline={1}
                                            size="mini"
                                            key={'numberOfLicences'}
                                            onChange={(_, data) => setFieldValue(data, 'numberOfLicences')}
                                            type="number"
                                            name="numberOfLicences"
                                            placeholder="Licenses"
                                            value={values.numberOfLicences} />
                                    </Form.Field>
                                </Form.Group>
                            </div>
                            <div className="middle-section__right">
                                <div className="trial">
                                    <Form.Checkbox
                                        label="Trial"
                                        type="checkbox"
                                        onChange={(_, props) => setCheckboxValue(props, 'isTrial')}
                                        name="isTrial"
                                        checked={values.isTrial} />
                                    {
                                        values.isTrial && (
                                            <div style={{ marginBottom: '15px' }}>
                                                <DateRangePicker
                                                    displayFormat={() => "DD/MM/YYYY"}
                                                    startDate={values.trial.startTime}
                                                    endDate={values.trial.endTime}
                                                    onDatesChange={(dates: { startDate, endDate }) => handleDateChange(dates, values)}
                                                    noBorder={true}
                                                    startDateId={moment().toString()}
                                                    endDateId={moment().add('14', 'd').toString()}
                                                    focusedInput={focusedInput}
                                                    onFocusChange={focusedInput => changeFocusedInput(focusedInput)} />
                                                <p className="trial-warning">You won't be able to sign in before the trial start date</p>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div style={{
                            display: 'block',
                            margin: '0 0 .28571429rem 0',
                            color: 'rgba(0,0,0,.87)',
                            fontSize: '0.92857143em',
                            fontWeight: 700,
                            textTransform: 'none'
                        }}>
                            Upload client logo
                        </div>
                        {
                            picture &&
                            <div className='logo-container'>
                                <img src={picture} alt='Logo' />
                            </div>
                        }
                        <ImageUploader
                            withIcon={true}
                            buttonText='Choose images'
                            onChange={onImageDrop}
                            imgExtension={['.jpg', '.png', '.jpeg']}
                            maxFileSize={5242880} />
                        <DisableConfirmationModal
                            modalOpened={modalOpened}
                            isDisabled={values.disabled}
                            closeModal={(answer) => {
                                values.disabled = answer
                                setState(values);
                                setModalOpened(false)
                            }}
                        />
                    </Form>
                }} />
        </Tab.Pane>
    )
}

export default MainInfoTab;
