import React from 'react';
import { Router } from 'react-router-dom';
import { history } from '../../utils/history';
import { Provider } from 'react-redux';
import CustomRouter from './router';

const App = (props) => {
    return (
        <Provider store={props.store}>
            <Router history={history}>
                <CustomRouter />
            </Router>
        </Provider>
    );
}

export default App;

