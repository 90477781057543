import { Routine } from "redux-saga-routines";
import {
    getAverageScreensDataForGroupRoutine,
    getAverageScreensDataRoutine,
    getContentProvidersDataForGroupRoutine,
    getContentProvidersDataRoutine,
    getContractSpecDataForGroupRoutine,
    getContractSpecDataRoutine,
    getDownloadsDataForGroupRoutine,
    getDownloadsDataRoutine,
    getEventsDataForGroupRoutine,
    getEventsDataRoutine,
    getLocationsDataForGroupRoutine,
    getLocationsDataRoutine,
    getSessionsByDeviceDataForGroupRoutine,
    getSessionsByDeviceDataRoutine,
    getSessionsDataForGroupRoutine,
    getSessionsDataRoutine,
    getSportsDataForGroupRoutine,
    getSportsDataRoutine,
    getStatisticsTableDataForGroupRoutine,
    getStatisticsTableDataRoutine,
    getStreamFormatDataForGroupRoutine,
    getStreamFormatDataRoutine,
    getStreamFormatDurationDataForGroupRoutine,
    getStreamFormatDurationDataRoutine,
    getTotalDurationDataForGroupRoutine,
    getTotalDurationDataRoutine,
    getUsersDataForGroupRoutine,
    getUsersDataRoutine
} from '../../routines';

export const logsState = {
    contractSpecLogs: {
        source: [],
        isLoading: false
    },
    summaryLogs: {
        source: [],
        isLoading: false
    },
    detailsLogs: {
        source: [],
        isLoading: false
    }
}

export const statisticsReducer = (state: any = logsState, action: Routine<any>) => {
    switch (action.type) {
        case getContractSpecDataRoutine.TRIGGER:
        case getContractSpecDataForGroupRoutine.TRIGGER:
            return {...state,
                contractSpecLogs: {
                    source: state.contractSpecLogs.source,
                    isLoading: true
                }
            }
        case getContractSpecDataRoutine.SUCCESS:
        case getContractSpecDataForGroupRoutine.SUCCESS:
            return {...state,
                contractSpecLogs: {
                    source: action.payload,
                    isLoading: false
                },
                summaryLogs: {
                    source: [],
                    isLoading: false
                },
                detailsLogs: {
                    source: [],
                    isLoading: false
                }
            }
        case getStatisticsTableDataRoutine.TRIGGER:
        case getStatisticsTableDataForGroupRoutine.TRIGGER:
            return {...state,
                summaryLogs: {
                    source: state.summaryLogs.source,
                    isLoading: true
                },
            }
        case getStatisticsTableDataRoutine.SUCCESS:
        case getStatisticsTableDataForGroupRoutine.SUCCESS:
            return {...state,
                summaryLogs: {
                    source: action.payload,
                    isLoading: false
                },
                contractSpecLogs: {
                    source: [],
                    isLoading: false
                },
                detailsLogs: {
                    source: [],
                    isLoading: false
                }
            }
        case getUsersDataRoutine.TRIGGER:
        case getSessionsDataRoutine.TRIGGER:
        case getSessionsByDeviceDataRoutine.TRIGGER:
        case getTotalDurationDataRoutine.TRIGGER:
        case getStreamFormatDataRoutine.TRIGGER:
        case getStreamFormatDurationDataRoutine.TRIGGER:
        case getSportsDataRoutine.TRIGGER:
        case getAverageScreensDataRoutine.TRIGGER:
        case getLocationsDataRoutine.TRIGGER:
        case getDownloadsDataRoutine.TRIGGER:
        case getEventsDataRoutine.TRIGGER:
        case getContentProvidersDataRoutine.TRIGGER:
        case getUsersDataForGroupRoutine.TRIGGER:
        case getSessionsDataForGroupRoutine.TRIGGER:
        case getSessionsByDeviceDataForGroupRoutine.TRIGGER:
        case getTotalDurationDataForGroupRoutine.TRIGGER:
        case getStreamFormatDataForGroupRoutine.TRIGGER:
        case getStreamFormatDurationDataForGroupRoutine.TRIGGER:
        case getSportsDataForGroupRoutine.TRIGGER:
        case getAverageScreensDataForGroupRoutine.TRIGGER:
        case getLocationsDataForGroupRoutine.TRIGGER:
        case getDownloadsDataForGroupRoutine.TRIGGER:
        case getEventsDataForGroupRoutine.TRIGGER:
        case getContentProvidersDataForGroupRoutine.TRIGGER:
            return {...state,
                detailsLogs: {
                    source: state.detailsLogs.source,
                    isLoading: true
                },
                contractSpecLogs: {
                    source: [],
                    isLoading: false
                },
                summaryLogs: {
                    source: [],
                    isLoading: false
                }
            }
        case getUsersDataRoutine.SUCCESS:
        case getSessionsDataRoutine.SUCCESS:
        case getSessionsByDeviceDataRoutine.SUCCESS:
        case getTotalDurationDataRoutine.SUCCESS:
        case getStreamFormatDataRoutine.SUCCESS:
        case getStreamFormatDurationDataRoutine.SUCCESS:
        case getSportsDataRoutine.SUCCESS:
        case getAverageScreensDataRoutine.SUCCESS:
        case getLocationsDataRoutine.SUCCESS:
        case getDownloadsDataRoutine.SUCCESS:
        case getEventsDataRoutine.SUCCESS:
        case getContentProvidersDataRoutine.SUCCESS:
        case getUsersDataForGroupRoutine.SUCCESS:
        case getSessionsDataForGroupRoutine.SUCCESS:
        case getSessionsByDeviceDataForGroupRoutine.SUCCESS:
        case getTotalDurationDataForGroupRoutine.SUCCESS:
        case getStreamFormatDataForGroupRoutine.SUCCESS:
        case getStreamFormatDurationDataForGroupRoutine.SUCCESS:
        case getSportsDataForGroupRoutine.SUCCESS:
        case getAverageScreensDataForGroupRoutine.SUCCESS:
        case getLocationsDataForGroupRoutine.SUCCESS:
        case getDownloadsDataForGroupRoutine.SUCCESS:
        case getEventsDataForGroupRoutine.SUCCESS:
        case getContentProvidersDataForGroupRoutine.SUCCESS:
            return {...state,
                detailsLogs: {
                    source: action.payload,
                    isLoading: false
                },
                contractSpecLogs: {
                    source: [],
                    isLoading: false
                },
                summaryLogs: {
                    source: [],
                    isLoading: false
                }
            }
        default:
            return state;
    }
}














