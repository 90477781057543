type Environment = {
    apiUrl: string,
    cmsUrl: string,
    inplayUrl: string,
    hashKey: string
    envType: string;
}

const env: Environment = {
    apiUrl: process.env.REACT_APP_API_URL as string,
    cmsUrl: process.env.REACT_APP_CMS_URL as string,
    inplayUrl: process.env.REACT_APP_INPLAY_URL as string,
    hashKey: process.env.REACT_APP_HASH_KEY as string,
    envType: process.env.REACT_APP_ENV_TYPE as string
};

export default env;