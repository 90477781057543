import { createRoutine } from 'redux-saga-routines';

export const fetchUser = createRoutine('FETCH_USER');
export const loadingUser = createRoutine('LOADING_USER');

export const fetchDevicesRoutine = createRoutine('FETCH_USER_DEVICES');
export const removeDeviceRoutine = createRoutine('REMOVE_USER_DEVICE');
export const removeAllDevicesRoutine = createRoutine('REMOVE_ALL_USER_DEVICES');
export const removeGroupDevicesRoutine = createRoutine('REMOVE_GROUP_DEVICES');

export const fetchRoles = createRoutine('FETCH_ROLES');
export const fetchSports = createRoutine('FETCH_SPORTS');
export const fetchCountries = createRoutine('FETCH_COUNTRIES');

export const fetchCompetitions = createRoutine('FETCH_COMPETITIONS');
export const createGroup = createRoutine('CREATE_GROUP');
export const putGroups = createRoutine('PUT_GROUP');
export const fetchGroup = createRoutine('GET_GROUP');
export const fetchGroups = createRoutine('GET_GROUPS');
export const deleteGroup = createRoutine('DELETE_GROUP');
export const restoreGroupRoutine = createRoutine('RESTORE_GROUP');
export const toggleIndividualRestrictions = createRoutine('TOGGLE_INDIVIDUAL_RESTRICTIONS');
export const toggleExternalUserRoutine = createRoutine('TOGGLE_EXTERNAL_USER');

export const addUserToGroup = createRoutine('ADD_USER_TO_GROUP');
export const updateUserInGroup = createRoutine('UPDATE_USER_IN_GROUP');
export const removeOrRestoreUserByEmailRoutine = createRoutine('REMOVE_OR_RESTORE_USER');
export const removeUserRoutine = createRoutine('DELETE_USER');

export const clearAlreadyDisabledFields = createRoutine('CLEAR_DISABLED_FIELDS')

export const getCountOfDistinctEventsForTiersRoutine = createRoutine('GET_COUNT_OF_DICTINCT_EVENTS_FOR_TIERS');
export const getDetailedEventsForTiersRoutine = createRoutine('GET_DETAILED_EVENTS_FOR_TIERS');

export const resetPassword = createRoutine('RESET_PASSWORD');

export const getServerAliasesRoutine = createRoutine('GET_SERVER_ALIASES');
export const createServerAliasRoutine = createRoutine('CREATE_SERVER_ALIAS');
export const updateServerAliasRoutine = createRoutine('UPDATE_SERVER_ALIAS');
export const deleteServerAliasRoutine = createRoutine('DELETE_SERVER_ALIAS');

export const getStreamSettingsRoutine = createRoutine('GET_STREAM_SETTINGS');
export const createStreamSettingRoutine = createRoutine('CREATE_STREAM_SETTING');
export const updateStreamSettingRoutine = createRoutine('UPDATE_STREAM_SETTING');
export const deleteStreamSettingRoutine = createRoutine('DELETE_STREAM_SETTING');

export const checkIsGroupHasContractRoutine = createRoutine('CHECK_IS_GROUP_HAS_CONTRACT');

export const getContractSpecDataRoutine = createRoutine('GET_CONTRACT_SPEC_DATA');
export const getContractSpecDataForGroupRoutine = createRoutine('GET_CONTRACT_SPEC_DATA_FOR_GROUP');

export const getStatisticsTableDataRoutine = createRoutine('GET_STATISTICS_TABLE_DATA');
export const getStatisticsTableDataForGroupRoutine = createRoutine('GET_STATISTICS_TABLE_DATA_FOR_GROUP');

export const getUsersDataRoutine = createRoutine('GET_USERS_DATA');
export const getUsersDataForGroupRoutine = createRoutine('GET_USERS_DATA_FOR_GROUP');

export const getSessionsDataRoutine = createRoutine('GET_SESSIONS_DATA');
export const getSessionsDataForGroupRoutine = createRoutine('GET_SESSIONS_DATA_FOR_GROUP');

export const getSessionsByDeviceDataRoutine = createRoutine('GET_SESSIONS_BY_DEVICE_DATA');
export const getSessionsByDeviceDataForGroupRoutine = createRoutine('GET_SESSIONS_BY_DEVICE_DATA_FOR_GROUP');

export const getTotalDurationDataRoutine = createRoutine('GET_TOTAL_DURATION_DATA');
export const getTotalDurationDataForGroupRoutine = createRoutine('GET_TOTAL_DURATION_DATA_FOR_GROUP');

export const getStreamFormatDataRoutine = createRoutine('GET_STREAM_FORMAT_DATA');
export const getStreamFormatDataForGroupRoutine = createRoutine('GET_STREAM_FORMAT_DATA_FOR_GROUP');

export const getStreamFormatDurationDataRoutine = createRoutine('GET_STREAM_FORMAT_DURATION_DATA');
export const getStreamFormatDurationDataForGroupRoutine = createRoutine('GET_STREAM_FORMAT_DURATION_DATA_FOR_GROUP');

export const getSportsDataRoutine = createRoutine('GET_SPORTS_DATA');
export const getSportsDataForGroupRoutine = createRoutine('GET_SPORTS_DATA_FOR_GROUP');

export const getAverageScreensDataRoutine = createRoutine('GET_AVERAGE_SCREENS_DATA');
export const getAverageScreensDataForGroupRoutine = createRoutine('GET_AVERAGE_SCREENS_DATA_FOR_GROUP');

export const getLocationsDataRoutine = createRoutine('GET_LOCATIONS_DATA');
export const getLocationsDataForGroupRoutine = createRoutine('GET_LOCATIONS_DATA_FOR_GROUP');

export const getDownloadsDataRoutine = createRoutine('GET_DOWNLOADS_DATA');
export const getDownloadsDataForGroupRoutine = createRoutine('GET_DOWNLOADS_DATA_FOR_GROUP');

export const getEventsDataRoutine = createRoutine('GET_EVENTS_DATA');
export const getEventsDataForGroupRoutine = createRoutine('GET_EVENTS_DATA_FOR_GROUP');

export const getContentProvidersDataRoutine = createRoutine('GET_CONTENT_PROVIDERS_DATA');
export const getContentProvidersDataForGroupRoutine = createRoutine('GET_CONTENT_PROVIDERS_DATA_FOR_GROUP');

export const getSsoDomainsRoutine = createRoutine('GET_SSO_DOMAINS');
export const createSsoDomainRoutine = createRoutine('CREATE_SSO_DOMAIN');
export const updateSsoDomainRoutine = createRoutine('UPDATE_SSO_DOMAIN');
export const deleteSsoDomainRoutine = createRoutine('DELETE_SSO_DOMAIN');

export const getAnnouncementsRoutine = createRoutine('GET_ANNOUNCEMENTS');
export const createAnnouncementRoutine = createRoutine('CREATE_ANNOUNCEMENT');
export const updateAnnouncementRoutine = createRoutine('UPDATE_ANNOUNCEMENT');
export const deleteAnnouncementRoutine = createRoutine('DELETE_ANNOUNCEMENT');


