import { Routine } from 'redux-saga-routines';
import { getServerAliasesRoutine, createServerAliasRoutine, updateServerAliasRoutine, deleteServerAliasRoutine } from '../../routines';
import { ServerAlias } from '../../../common/types/server-alias';

export const serverAliasesReducer = (state: ServerAlias[] | null = null, action: Routine<any>) => {
    switch (action.type) {
        case getServerAliasesRoutine.SUCCESS:
            return action.payload;
        case createServerAliasRoutine.SUCCESS:
            const newState = state!.filter(i => i.id !== action.payload.id)
            return [
                ...newState,
                action.payload
            ]
        case deleteServerAliasRoutine.TRIGGER:
            const stateWithoutDeleted = state!.filter(i => i.id !== action.payload);

            return stateWithoutDeleted;
        case updateServerAliasRoutine.SUCCESS:
            const indexUpdated = state!.findIndex(i => i.id === action.payload.id);
            const updatedState = state;
            updatedState![indexUpdated as number] = action.payload;

            return [...updatedState as ServerAlias[]];
        default:
            return state;

    }
}



